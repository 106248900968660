import { core, usStreet } from "smartystreets-javascript-sdk";
import { LatLong } from "../useSmartyAddress/useSmartyAddress.types";

const { Lookup } = usStreet;

const key = "120285478463721873";

const clientBuilder = new core.ClientBuilder(new core.SharedCredentials(key));
const client = clientBuilder.buildUsStreetApiClient();

function useAddressValidation() {
  const addressQuery = async (
    street: string,
    city: string,
    state: string
  ): Promise<LatLong> => {
    const l = new Lookup();
    l.street = street;
    l.city = city;
    l.state = state;
    try {
      const result = await client.send(l);
      const smartyResult: LatLong = result.lookups
        .flatMap((lu) =>
          lu.result.flatMap((r) => ({
            lat: r.metadata.latitude,
            long: r.metadata.longitude,
          }))
        )
        .reduce((_, c) => c);
      return smartyResult;
    } catch (e) {
      throw new Error("error validating address");
    }
  };

  return { addressQuery };
}

export default useAddressValidation;
