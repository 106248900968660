import React, { useState } from "react";
import ReactCrop, {
  centerCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { styled } from "@mui/material";

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const StyledCrop = styled(ReactCrop)(({ theme }) => ({
  maxWidth: "400px",
  maxHeight: "400px",
  [theme.breakpoints.up("md")]: {
    maxHeight: "600px ",
    maxWidth: "600px ",
  },
}));

interface ICroppableImageProps {
  imgRef: any;
  imgSrc: string;
  aspect: number;
  onCompleteCrop: (c: PixelCrop) => void;
}

function CroppableImage({
  imgRef,
  imgSrc,
  aspect,
  onCompleteCrop,
}: ICroppableImageProps) {
  const [crop, setCrop] = useState<Crop>();

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, aspect));
  };

  return (
    <StyledCrop
      crop={crop}
      onChange={(pixelCrop) => setCrop(pixelCrop)}
      onComplete={onCompleteCrop}
      aspect={aspect}
    >
      <img src={imgSrc} ref={imgRef} alt="crop" onLoad={handleImageLoad} />
    </StyledCrop>
  );
}

export default CroppableImage;
