import React, {useRef, useState} from "react";
import { Box, Grid, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import ReachOutModal from "../../../../components/ReachOutModal/ReachOutModal";
import CounselorDisplay from "./components/CounselorDisplay/CounselorDisplay";
import CounselorInformation from "./components/CounselorInformation/CounselorInformation";
import useScrollTransition from "../../../../hooks/useScrollTransition/useScrollTransition";
import SlideBar from "./components/SlideBar/SlideBar";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import CounselorVideoModal from "./components/CounselorVideoModal/CounselorVideoModal";
import useReachOut from "../../../../hooks/useReachOut/useReachOut";
import WaveBackground from "../../../../components/WaveBackground/WaveBackground";
import { TherapistData } from "../../../../api/queries.types";
import usePageView from "../../../../hooks/usePageView/usePageView";

interface ICounselorProfile {
  onBack?: () => void;
  preview: boolean;
  counselor: TherapistData;
}

function CounselorProfile({ onBack, preview, counselor }: ICounselorProfile) {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("md"));
  const ref = useRef();
  const [openDialog, setOpenDialog] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const onScreen = useScrollTransition({ element: ref, offset: 80 });

  const {
    mutation: contactMutation,
    reset: contactReset,
    success: contactSuccess,
    loading: contactLoading,
  } = useReachOut();

  const { givenName, familyName } = counselor.therapist.therapist;

  const handleCloseDialog = (_: any, reason: string) => {
    if (reason !== "backdropClick") {
      setOpenDialog(false);
      contactReset();
    }
  };

  const handleReachOut = () => {
    contactMutation.mutate({ therapistId: counselor.therapist.therapist.id! });
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleOpenVideo = () => {
    setOpenDialog(false);
    setOpenVideo(true);
  };

  const handleCloseVideo = () => {
    setOpenVideo(false);
  };

  usePageView({ path: "/app/client/counselor-profile" });

  return (
    <WaveBackground>
      <PageContainer>
        <Grid
          container
          flexWrap="wrap"
          spacing={4}
          sx={{ position: "relative" }}
        >
          <Grid item xs={12} lg={4}>
            {!preview && (
              <SlideBar
                visible={!onScreen}
                counselor={counselor}
                onClick={handleOpenDialog}
                desktop={match}
              />
            )}
            <Box ref={ref} sx={{ opacity: onScreen || !match ? 1 : 0 }}>
              <CounselorDisplay
                counselor={counselor}
                onClick={handleOpenDialog}
                preview={preview}
                onBack={onBack}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={8}>
            <CounselorInformation
              counselor={counselor}
              onVideoClick={handleOpenVideo}
            />
          </Grid>
        </Grid>
        {!preview && (
          <ReachOutModal
            open={openDialog}
            onClose={handleCloseDialog}
            onContact={() => handleReachOut()}
            name={`${givenName} ${familyName.charAt(0)}`}
            loading={contactLoading}
            success={contactSuccess}
          />
        )}
        {!preview && !match && <Toolbar />}
      </PageContainer>
      <CounselorVideoModal open={openVideo} onClose={handleCloseVideo} />
    </WaveBackground>
  );
}

export default CounselorProfile;
