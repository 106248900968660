import React from "react";
import {
  Button,
  Grid,
  LinearProgress,
  linearProgressClasses,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import EditProfileCard from "../EditProfileCard/EditProfileCard";

const StyledProgress = styled(LinearProgress)(({ theme }) => ({
  height: "32px",
  width: "100%",
  minWidth: "200px",
  borderRadius: theme.spacing(2),
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[300],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
}));

interface IProfileCompletionBannerProps {
  percentage: number;
  canPublish: boolean;
  isPublished: boolean;
  onPreview: () => void;
}

// TODO - publish profile button working
function ProfileCompletionBanner({
  percentage,
  canPublish,
  isPublished,
  onPreview,
}: IProfileCompletionBannerProps) {
  return (
    <EditProfileCard>
      {canPublish}
      <Stack spacing={1}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">
              <b>Profile Completion</b>
            </Typography>

            <Typography variant="subtitle2">
              Fully complete your profile to match with your ideal client
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledProgress variant="determinate" value={percentage} />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="baseline">
          {!isPublished && canPublish && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                sx={{ alignSelf: "flex-start", p: 3, pt: 2, pb: 2 }}
                onClick={onPreview}
              >
                Publish Profile
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              variant="text"
              sx={{ alignSelf: "flex-start" }}
              onClick={onPreview}
            >
              Preview Profile <ChevronRight />
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </EditProfileCard>
  );
}

export default ProfileCompletionBanner;
