import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import {
  Address,
  AddressChangeHandler,
} from "../../pages/authenticated/therapist/EditProfile/components/PracticeDetailsForm/PracticeDetailsForm.types";
import useSmartyAddress from "../../hooks/useSmartyAddress/useSmartyAddress";

interface IAddressAutocompleteProps {
  onChange: AddressChangeHandler;
  value: Address;
}

function AddressAutocomplete({ value, onChange }: IAddressAutocompleteProps) {
  const { handleInput, list } = useSmartyAddress();
  return (
    <Autocomplete<Address>
      disablePortal
      id="address-locations-auto"
      options={list}
      sx={{ width: "100%" }}
      onChange={(_, newValue) => onChange(newValue)}
      onInputChange={(_, v) => handleInput(v)}
      value={value}
      getOptionLabel={(o) => (o === null ? "" : o.street)}
      renderOption={(p, o) => (
        <li {...p}>{`${o.street} ${o.city}, ${o.state}`}</li>
      )}
      renderInput={(params) => {
        const { inputProps, ...rest } = params;
        return (
          <TextField
            {...rest}
            variant="filled"
            fullWidth
            inputProps={{
              ...inputProps,
              "data-test": "location-input",
            }}
            /* eslint-disable-next-line react/jsx-no-duplicate-props */
            label="Address"
          />
        );
      }}
    />
  );
}

export default AddressAutocomplete;
