import React from "react";
import {
  Box,
  Chip,
  Fade,
  Grow,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  AccountCircle,
  ChevronLeft,
  Email,
  Launch,
  Phone,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import VerifiedBanner from "../../../../../../components/VerifiedBanner/VerifiedBanner";
import LocationDisplay from "../../../../../../components/LocationDisplay/LocationDisplay";
import RadiusButton from "../../../../../../components/RadiusButton/RadiusButton";
import CounselorImage from "../../../../../../components/CounselorImage/CounselorImage";
import { TherapistData } from "../../../../../../api/queries.types";
import ReachOutAlert from "../../../../../../components/ReachOutAlert/ReachOutAlert";

interface ICounselorDisplayProps {
  counselor: TherapistData;
  onClick: () => void;
  preview?: boolean;
  onBack?: () => void;
}

// TODO - After reach out buttons
// TODO - counselor profile avatar
function CounselorDisplay({
  counselor,
  preview,
  onClick,
  onBack,
}: ICounselorDisplayProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down("md"));

  const {
    givenName,
    familyName,
    address,
    city,
    state,
    hideAddress,
    website,
    phoneNumber,
    practiceName,
    practiceEmail,
  } = counselor.therapist.therapist;

  const { profileImagePath, licenses } = counselor.therapist;

  const name = counselor.contactStatus === "ACCEPTED"
    ? `${givenName} ${familyName}`
    : `${givenName} ${familyName.charAt(0)}`;

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Stack
        spacing={{ xs: 2, lg: 1 }}
        direction={{ xs: "column", md: "row", lg: "column" }}
        justifyContent="center"
        alignItems={{ lg: "center", xs: "flex-start" }}
      >
        <Stack alignItems="center">
          {(!match || preview) && (
            <Box
              sx={{
                textAlign: "center",
                mb: 2,
              }}
            >
              <RadiusButton
                onClick={() =>
                  onBack ? onBack() : navigate(-1)
                }
                color={preview ? "secondary" : "primary"}
                variant={preview ? "contained" : "text"}
                disableElevation
                sx={{ whiteSpace: "nowrap" }}
              >
                <ChevronLeft />
                {preview ? "Back to Edit Profile" : "Back"}
              </RadiusButton>
            </Box>
          )}
          {profileImagePath ? (
            <CounselorImage src={`${profileImagePath}`} alt={givenName} />
          ) : (
            <AccountCircle
              sx={{ height: "140px", width: "140px", color: "grey.600" }}
            />
          )}
        </Stack>
        <Stack spacing={2} justifyContent={{ xs: "flex-start", lg: "center" }}>
          <Box>
            <Typography variant="h4" textAlign="center" data-test="therapistName">
              <b>{name}</b>
            </Typography>
            {practiceName && (
              <Typography
                variant="subtitle2"
                textAlign="center"
                sx={{
                  color: "grey.700",
                }}
                data-test="practiceName"
              >
                {practiceName}
              </Typography>
            )}
          </Box>
          <Stack spacing={1} data-test="licenses">
            {licenses &&
              licenses.map((t) => (
                <Chip
                  label={`${t.licenseNumber} (${t.licenseState})`}
                  sx={{ mt: 1 }}
                  size="small"
                  key={t.licenseNumber}
                />
              ))}
          </Stack>
          <VerifiedBanner
            sx={{ justifyContent: { xs: "flex-start", lg: "center" } }}
          />
          {city && state && address && !hideAddress && (
            <LocationDisplay
              location={`${address} ${city}, ${state}`}
              sx={{
                display: "flex",
                justifyContent: { xs: "flex-start", lg: "center" },
              }}
            />
          )}
          {counselor.contactStatus === "ACCEPTED" ||
           counselor.contactStatus === "PENDING" ||
           counselor.contactStatus === "DECLINED" ? (
            <>
              <Grow in>
                <div>
                  <ReachOutAlert
                    status={
                      counselor.contactStatus
                    }
                  />
                </div>
              </Grow>
              {counselor.contactStatus === "ACCEPTED" && (
                <>
                  <Typography variant="subtitle2" sx={{ color: "grey.700" }}>
                    Contact Information
                  </Typography>
                  <Fade in={Boolean(phoneNumber)} unmountOnExit>
                    <Chip
                      icon={<Phone />}
                      label={phoneNumber}
                      clickable
                      component="a"
                      href={`tel:${phoneNumber}`}
                    />
                  </Fade>
                  <Fade in={Boolean(practiceEmail)} unmountOnExit>
                    <Chip
                      icon={<Email />}
                      label={practiceEmail}
                      clickable
                      component="a"
                      href={`mailto:${practiceEmail}`}
                    />
                  </Fade>
                  <Fade in={Boolean(website)} unmountOnExit>
                    <RadiusButton
                      variant="contained"
                      sx={{
                        bgcolor: "grey.100",
                        pb: 2,
                        pt: 2,
                        color: "primary.light",
                        "&:hover": {
                          bgcolor: "grey.200",
                        },
                      }}
                      radius={1}
                      onClick={() => window.open(website, "_blank")}
                      data-test="website"
                    >
                      Visit Website <Launch sx={{ ml: 2, fontSize: "1rem" }} />
                    </RadiusButton>
                  </Fade>
                </>
              )}
            </>
          ) : (
            <Grow in>
              <RadiusButton
                variant="contained"
                color="primary"
                radius={1}
                sx={{ pt: 2, pb: 2 }}
                onClick={onClick}
              >
                Reach Out
              </RadiusButton>
            </Grow>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}

export default CounselorDisplay;
