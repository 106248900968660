import React from "react";
import { Box, Modal } from "@mui/material";

interface ICounselorVideoModalProps {
  open: boolean;
  onClose: () => void;
}

function CounselorVideoModal({ open, onClose }: ICounselorVideoModalProps) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", md: "600px" },
          aspectRatio: "16 / 9",
          bgcolor: "primary.light",
        }}
      />
    </Modal>
  );
}

export default CounselorVideoModal;
