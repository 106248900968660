import React, { useEffect, useState } from "react";
import { Tab, Tabs, tabsClasses } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { INavItem } from "./Navigator.types";

interface INavigatorProps {
  items: INavItem[];
}

function Navigator({ items }: INavigatorProps) {
  const navigate = useNavigate();
  const location = useLocation();
  // on render get current route
  const [tab, setTab] = useState(
    items.findIndex((i) =>
      typeof i.action === "string"
        ? i.action.includes(location.pathname)
        : false
    ) ?? -1
  );
  // disable on routes not included in items[]
  useEffect(() => {
    setTab(
      items.findIndex((i) =>
        typeof i.action === "string"
          ? i.action.includes(location.pathname)
          : false
      ) ?? -1
    );
  }, [location]);

  const handleTabChange = (e: React.ChangeEvent<{}>, i: number) => {
    setTab(i);
  };

  return (
    <Tabs
      onChange={handleTabChange}
      value={tab}
      color="secondary"
      indicatorColor="secondary"
      textColor="secondary"
      variant="scrollable"
      allowScrollButtonsMobile
      sx={{
        [`& .${tabsClasses.scrollButtons}`]: {
          color: "black",
          opacity: "0.4",
        },
      }}
    >
      {items.map((m) => (
        <Tab
          key={m.label}
          sx={{
            fontWeight: "bold",
            width: "30%",
            color: "grey.400",
            minWidth: "180px",
          }}
          label={m.label}
          {...(typeof m.action === "string"
            ? { onClick: () => navigate(m.action as string) }
            : { onClick: m.action })}
        />
      ))}
    </Tabs>
  );
}

export default Navigator;
