import React from "react";
import MultiselectForm from "../MultiselectForm/MultiselectForm";
import { IQuestionProps } from "./QuestionFactory.types";

interface IQuestionFactoryProps extends IQuestionProps {}

function QuestionFactory({
  question,
  responses,
  onCancel,
}: IQuestionFactoryProps) {
  switch (question.format) {
    case "MC":
      return (
        <MultiselectForm
          question={question}
          responses={responses}
          onCancel={onCancel}
        />
      );
    default:
      return <div />;
  }
}

export default QuestionFactory;
