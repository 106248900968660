import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
  styled,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  background: theme.palette.grey["100"],
  borderRadius: `${theme.spacing(2, 2, 2, 2)} !important`,
}));

// @ts-ignore
const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  paddingBottom: 0,
  paddingTop: 0,
  color: theme.palette.primary.main,
  [`&.${accordionSummaryClasses.expanded}`]: {
    minHeight: "0px",
  },
}));

interface ISectionAccordionProps {
  title: string;
  children: React.ReactNode;
}

function SectionAccordion({ title, children }: ISectionAccordionProps) {
  return (
    <StyledAccordion elevation={0} defaultExpanded>
      <StyledAccordionSummary
        expandIcon={<ExpandMore sx={{ color: "primary.main" }} />}
      >
        <Typography sx={{ width: "33%", flexShrink: 0, fontWeight: "500" }}>
          {title}
        </Typography>
      </StyledAccordionSummary>
      <AccordionDetails sx={{ pt: 0, pb: 2 }}>{children}</AccordionDetails>
    </StyledAccordion>
  );
}

export default SectionAccordion;
