import React, {useState} from "react";
import { Grid, Stack } from "@mui/material";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import CounselorInformationCard from "./components/CounselorInformationCard/CounselorInformationCard";
import AdditionalPhotosCard from "./components/AdditionalPhotosCard/AdditionalPhotosCard"
import ProfileCompletionBanner from "./components/ProfileCompletionBanner/ProfileCompletionBanner";
import ProfileTabs from "./components/ProfileTabs/ProfileTabs";
import useTherapistData from "../../../../hooks/useTherapistData/useTherapistData";
import CounselorPreviewDataProvider from "./components/CounselorPreviewDataProvider/CounselorPreviewDataProvider";
import { IImage } from "./components/CounselorImageUpload/CounselorImageUpload.types";
import usePageView from "../../../../hooks/usePageView/usePageView";

function EditProfile() {
  const [preview, setPreview] = useState(false);
  const { loading, error, data: gqlData } = useTherapistData();

  usePageView({ path: "/app/therapist/edit-profile" });

  if (error) {
    return <PageContainer>Error</PageContainer>;
  }
  if (loading) {
    return <div />;
  }

  const handlePreview = () => {
    setPreview(!preview);
  };

  const therapist = gqlData!.therapistProfile!.state!;

  if (preview) {
    return <CounselorPreviewDataProvider onBack={handlePreview} />;
  }
  const profileImage = therapist.therapistProfileImage
    ? ({
      id: therapist.therapistProfileImage!.id,
      path: therapist.therapistProfileImage!.profileImagePath!,
    } as IImage)
    : undefined;
  const featureImages =
    (therapist.therapistFeatureImages?.map((f) => ({
      id: f!.id,
      path: f!.path,
    })) as IImage[]) ?? [];

  return (
    <PageContainer>
      <Grid
        container
        flexWrap="wrap"
        justifyContent="center"
        spacing={4}
        sx={{ position: "relative" }}
      >
        <Grid item xs={12} sm={7} md={4} lg={3}>
          <Stack spacing={2}>
            <CounselorInformationCard
              givenName={therapist.therapist!.givenName!}
              familyName={therapist.therapist!.familyName!}
              imagePath={profileImage}
            />
            <AdditionalPhotosCard images={featureImages} />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={7} md={8} lg={9}>
          <Stack spacing={2}>
            <ProfileCompletionBanner
              percentage={therapist.percentComplete ?? 0}
              canPublish={therapist.therapist?.publishable ?? false}
              isPublished={false}
              onPreview={handlePreview}
            />
            <ProfileTabs
              questions={therapist.respondedQuestions ?? []}
              therapist={therapist.therapist!}
              licenses={therapist.therapistLicenses!.map((t) => t!)}
            />
          </Stack>
        </Grid>
      </Grid>
    </PageContainer>
  );
}

export default EditProfile;
