import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import UnauthenticatedLayout from "../../layouts/UnauthenticatedLayout/UnauthenticatedLayout";
import NotFound from "../../pages/common/NotFound/NotFound";
import TherapistRegistration from "../../pages/unauthenticated/therapist/Registration/TherapistRegistration";
import RedirectToClientApp from "../RedirectToClientApp";

function GeneralRouter() {
  return (
    <Routes>
      <Route element={<UnauthenticatedLayout />}>
        <Route index element={<RedirectToClientApp />} />
        <Route path="client" element={<RedirectToClientApp />} />
        <Route
          path="therapist"
          element={<Navigate replace to="/therapist/register" />}
        />
        <Route path="location" element={<RedirectToClientApp />} />
        <Route path="therapist/register" element={<TherapistRegistration />} />
        <Route path="register" element={<RedirectToClientApp />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default GeneralRouter;
