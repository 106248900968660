import create from "zustand";
import {persist} from "zustand/middleware";
import {immer} from "../../pages/common/Onboard/types";
import {IUserStore} from "./useAuthentication.types";

const useAuthentication = create<IUserStore>(
  persist(
    immer((set) => ({
      user: null,
      setUser: (user) =>
        set((state) => {
          // eslint-disable-next-line no-param-reassign
          state.user = user;
        }),
    })),
    {
      name: "user-storage",
      getStorage: () => sessionStorage,
    }
  )
);

export default useAuthentication;
