import React from "react";
import { Stack, Typography } from "@mui/material";
import { useQuery } from "react-query";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import ConnectionCard from "./components/ConnectionCard/ConnectionCard";
import { getTherapistConnections } from "../../../../api/queries";
import Error from "../../../../components/Error/Error";
import usePageView from "../../../../hooks/usePageView/usePageView";

function Connections() {
  const { isLoading, isError, data } = useQuery(
    "therapistConnections",
    getTherapistConnections,
    {
      refetchOnWindowFocus: false,
    }
  );

  const getContent = () => {
    if (!data || data.length === 0) {
      return (
        <Typography variant="h6" sx={{ color: "grey.700" }}>
          <i>You do not currently have any referrals</i>
        </Typography>
      );
    }
    return data?.map((c) => (
      <ConnectionCard key={c.client.id} connection={c} />
    ));
  };

  usePageView({ path: "/app/therapist/connections" });

  if (isError) return <Error />;

  if (isLoading) return <div />;

  return (
    <PageContainer>
      <Typography variant="h4" component="h1">
        <b>My Referrals</b>
      </Typography>
      <Stack spacing={2} sx={{ mt: 2 }}>
        {getContent()}
      </Stack>
    </PageContainer>
  );
}

export default Connections;
