import React from "react";
import {Box, styled, Typography} from "@mui/material";
import RadiusButton from "../../../../../../components/RadiusButton/RadiusButton";

const StyledBox = styled(Box)<{ status?: string }>(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  width: "100%",
  border: `1px solid ${theme.palette.grey[300]}`,
  minHeight: "80px",
  boxSizing: "border-box"
}));

interface IResourceCardProps {
  title: string;
  description: string;
  pdf: string;
  docx: string;
}

function ResourceCard({ title, description, pdf, docx }: IResourceCardProps) {

  return (
    <StyledBox>
      <Typography variant="h6" component="h1" sx={{color: "#435563", fontWeight: "600"}}>
        {title}
      </Typography>
      <Typography variant="body1" component="p">
        {description}
      </Typography>
      <a href={pdf} target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>
      <RadiusButton
        variant="contained"
        sx={{
          bgcolor: "#3E5665",
          p: 2,
          ":hover": {
            bgcolor: "#F27269",
          },
          maxHeight: "55px",
          height: "45px",
          whiteSpace: "nowrap",
          m: 0.5
        }}
        radius={1.5}>
        .PDF
      </RadiusButton>
      </a>
      {docx &&
      <a href={docx} target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>
      <RadiusButton
      variant="contained"
      sx={{
        bgcolor: "#6BBCB9",
        p: 2,
        ":hover": {
          bgcolor: "#F27269",
        },
        maxHeight: "55px",
        height: "45px",
        whiteSpace: "nowrap",
        m: 0.5
      }}
      radius={1.5}>
      .DOCX
    </RadiusButton>
      </a>}
    </StyledBox>
  );
}

export default ResourceCard;