import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import RadiusButton from "../../../../../../components/RadiusButton/RadiusButton";

interface IAcceptDialogProps {
  open: boolean;
  name: string;
  onAccept: () => void;
  onClose: () => void;
}

function AcceptDialog({ open, name, onAccept, onClose }: IAcceptDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} data-test="acceptDialog">
      <DialogTitle>
        Would you like to provide therapeutic services to {name}?
      </DialogTitle>
      <DialogContent>
        After accepting {name}, we will notify them with your decision to provide therapy.<br/><br/>
          Your next step is to contact them to initiate the conversation.
      </DialogContent>
      <DialogActions sx={{ color: "grey.500" }}>
        <RadiusButton
          radius={3}
          variant="text"
          color="inherit"
          onClick={onClose}
          sx={{ minWidth: "115px", minHeight: "50px" }}
        >
          Close
        </RadiusButton>
        <RadiusButton
          radius={3}
          variant="text"
          color="primary"
          onClick={onAccept}
          sx={{ minWidth: "115px", minHeight: "50px" }}
        >
          Accept
        </RadiusButton>
      </DialogActions>
    </Dialog>
  );
}

export default AcceptDialog;
