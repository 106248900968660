import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import TabPanel from "../../../../../../components/TabPanel/TabPanel";
import EditableAccordion from "../EditableAccordion/EditableAccordion";
import FreeResponseForm from "../FreeResponseForm/FreeResponseForm";
import MultiInputForm from "../MultiInputForm/MultiInputForm";
import {
  TherapistProfile_therapist,
  TherapistProfile_therapistProfile_state_respondedQuestions,
  TherapistProfile_therapistProfile_state_therapistLicenses,
} from "../../../../../../api/graphql/gql_queries.types";
import QuestionFactory from "../QuestionFactory/QuestionFactory";
import PracticeDetailsForm from "../PracticeDetailsForm/PracticeDetailsForm";
import Toast from "../../../../../../components/Toast/Toast";
import ContactPreferencesForm from "../ContactPreferencesForm/ContactPreferencesForm";

interface IProfileTabsProps {
  questions?:
    | (TherapistProfile_therapistProfile_state_respondedQuestions | null)[]
    | null;
  therapist: TherapistProfile_therapist;
  licenses: TherapistProfile_therapistProfile_state_therapistLicenses[];
}

// TODO - hookup switch
function ProfileTabs({ questions, therapist, licenses }: IProfileTabsProps) {
  const [tab, setTab] = useState(0);
  const [expanded, setExpanded] = useState("");
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  function formatPhoneNumber(phoneNumberString: string | null) {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }

  const therapistPhoneNumber = formatPhoneNumber(therapist.phoneNumber);

  const personalInformationItems = [
    {
      component: <ContactPreferencesForm label="Mobile Phone Number" />,
      value: [therapist.mobilePhoneNumber, therapist.canText],
      required: true,
      title: "Contact Preferences",
      example: "Let us know how to reach you",
    },
    {
      component: <FreeResponseForm label="How I Can Help" />,
      value: [therapist.biography],
      required: true,
      title: "My Bio",
      example: "Describe how you can help people seeking therapy",
    },
    {
      component: <MultiInputForm label="Add License" />,
      value: licenses.map((l) => `${l.licenseNumber} (${l.licenseState})`),
      required: true,
      title: "Licenses",
      example: "Your professional licenses",
    },
    {
      component: <PracticeDetailsForm />,
      value: [
        therapist.city,
        therapist.state,
        therapist.practiceName,
        therapist.website,
        therapistPhoneNumber,
      ],
      required: true,
      title: "Practice Details",
      example: "Your practice details",
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setExpanded("");
    setTab(newValue);
  };

  const handleExpand = (newValue: string) => {
    if (expanded === newValue) {
      setExpanded("");
      return;
    }
    setExpanded(newValue);
  };

  const getResponseLabel = (
    respondedQuestion: TherapistProfile_therapistProfile_state_respondedQuestions
  ) =>
    respondedQuestion.responses?.map(
      (r) =>
        respondedQuestion.question?.options?.find((o) => o?.code === r)?.text ??
        ""
    );

  const handleClose = (val: string) => (message?: string) => {
    if (message) {
      setSuccessMessage(message);
    }
    handleExpand(val);
  };
  return (
    <Box>
      <Tabs
        value={tab}
        onChange={handleChange}
        aria-label="tabs"
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tab label="Personal Info" />
        <Tab label="Matching Criteria" />
      </Tabs>
      <TabPanel index={0} value={tab}>
        {personalInformationItems.map((b) => (
          <EditableAccordion
            key={b.title}
            expanded={expanded === b.title}
            preview={b.value}
            example={b.example}
            title={b.title}
            onExpand={() => handleExpand(b.title)}
            required={b.required}
          >
            <Box sx={{ p: 1, pt: 2 }}>
              {React.cloneElement(b.component, {
                onCancel: handleClose(b.title),
              })}
            </Box>
          </EditableAccordion>
        ))}
      </TabPanel>
      <TabPanel index={1} value={tab}>
        {questions &&
          questions.map((q) => (
            <EditableAccordion
              key={q!.question!.label!}
              expanded={expanded === q!.question?.label}
              preview={getResponseLabel(q!)}
              example=""
              title={q!.question?.label}
              onExpand={() => handleExpand(q!.question?.label ?? "")}
              // TODO - this needs to be added
              required
            >
              <Box sx={{ p: 1, pt: 2 }}>
                <QuestionFactory
                  question={q!.question!}
                  responses={q!.responses!}
                  onCancel={handleClose(q!.question?.label ?? "")}
                />
              </Box>
            </EditableAccordion>
          ))}
      </TabPanel>
      <Toast
        variant="success"
        text={successMessage ?? ""}
        open={Boolean(successMessage)}
        onClose={() => setSuccessMessage(null)}
      />
    </Box>
  );
}

export default ProfileTabs;
