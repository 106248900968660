import { gql } from "@apollo/client";

const DATE = gql`
  fragment Date on Date {
    date
    day
    hours
    minutes
    month
    seconds
    time
    timezoneOffset
    year
  }
`;

const LOCAL_DATE = gql`
  fragment LocalDate on LocalDate {
    chronology {
      calendarType
      id
    }
    dayOfMonth
    dayOfWeek {
      value
    }
    dayOfYear
    era {
      value
    }
    leapYear
    month {
      value
    }
    monthValue
    year
  }
`;

export const CURRENT_QUESTION = gql`
  fragment CurrentQuestion on Question {
    code
    format
    options {
      code
      text
    }
    text
    initialShowOptionCount
    maxResponsesAllowed
    label
    sortOrder
  }
`;
export const RESPONDED_QUESTION = gql`
  fragment Question on RespondedQuestion {
    question {
      code
      format
      options {
        code
        text
      }
      text
      initialShowOptionCount
      maxResponsesAllowed
      label
      sortOrder
    }
  }
`;

export const CURRENT_RESPONSES = gql`
  fragment CurrentResponses on Onboarding {
    currentResponses
  }
`;

export const THERAPIST = gql`
  fragment Therapist on Therapist {
    address
    biography
    city
    createdAt {
      ...Date
    }
    email
    familyName
    givenName
    hideAddress
    id
    latitude
    longitude
    phoneNumber
    mobilePhoneNumber
    canText
    practiceName
    practiceEmail
    website
    practiceName
    started {
      ...LocalDate
    }
    publishable
    state
  }
  ${DATE}
  ${LOCAL_DATE}
`;

export const THERAPIST_FEATURE_IMAGE = gql`
  fragment TherapistFeatureImage on TherapistFeatureImage {
    id
    path
    sub
  }
`;

export const THERAPIST_PROFILE_IMAGE = gql`
  fragment TherapistProfileImage on TherapistProfileImage {
    id
    profileImagePath
  }
`;

export const THERAPIST_PROFILE = gql`
  fragment TherapistProfile on TherapistProfile {
    percentComplete
    respondedQuestions {
      ...Question
      responses
    }
    therapist {
      ...Therapist
    }
    therapistFeatureImages {
      ...TherapistFeatureImage
    }
    therapistProfileImage {
      ...TherapistProfileImage
    }
    therapistLicenses {
      id
      licenseNumber
      licenseState
    }
  }
  ${THERAPIST_FEATURE_IMAGE}
  ${THERAPIST_PROFILE_IMAGE}
  ${RESPONDED_QUESTION}
  ${THERAPIST}
`;

export const ONBOARDING_STATE = gql`
  fragment OnboardingState on Onboarding {
    currentQuestion {
      ...CurrentQuestion
    }
    ...CurrentResponses
    hasNext
    previousQuestionCode
  }
  ${CURRENT_QUESTION}
  ${CURRENT_RESPONSES}
`;

export const GET_CURRENT_QUESTION = gql`
  query GetCurrentQuestion {
    onboarding {
      state {
        currentQuestion {
          ...CurrentQuestion
        }
      }
    }
  }
  ${CURRENT_QUESTION}
`;

export const GET_CURRENT_RESPONSES = gql`
  query GetCurrentResponses {
    onboarding {
      state {
        ...OnboardingState
      }
    }
  }
  ${ONBOARDING_STATE}
`;

export const START_ONBOARDING = gql`
  mutation StartOnboarding($flow: String, $surveyCode: String) {
    onboardingStart(action: { flow: $flow, surveyCode: $surveyCode }) {
      state {
        ...OnboardingState
      }
    }
  }
  ${ONBOARDING_STATE}
`;

export const ANSWER_ONBOARDING_QUESTION = gql`
  mutation OnboardingAnswer($questionCode: String, $responses: [String]) {
    onboardingResponse(
      action: { questionCode: $questionCode, responses: $responses }
    ) {
      state {
        ...OnboardingState
      }
    }
  }
  ${ONBOARDING_STATE}
`;

export const ONBOARDING_BACK = gql`
  mutation OnboardingBack {
    onboardingBack(action: { empty: "" }) {
      state {
        ...OnboardingState
      }
    }
  }
  ${ONBOARDING_STATE}
`;

export const ONBOARDING_SAVE = gql`
  mutation OnboardingSave {
    onboardingSave(action: { dummy: "" }) {
      state {
        ...OnboardingState
      }
    }
  }
  ${ONBOARDING_STATE}
`;

export const ONBOARDING_LOCATION = gql`
  mutation OnboardingLocation(
    $city: String
    $latitude: Float
    $longitude: Float
    $state: String
  ) {
    onboardingLocation(
      action: {
        city: $city
        latitude: $latitude
        longitude: $longitude
        state: $state
      }
    ) {
      state {
        ...OnboardingState
      }
    }
  }
  ${ONBOARDING_STATE}
`;

export const ONBOARDING_PHONE_NUMBER = gql`
  mutation OnboardingPhoneNumber(
    $phoneNumber: String
  ) {
    onboardingPhoneNumber(
      action: {
        phoneNumber: $phoneNumber
      }
    ) {
      state {
        ...OnboardingState
      }
    }
  }
  ${ONBOARDING_STATE}
`;

export const ONBOARDING_GIVEN_NAME = gql`
  mutation OnboardingGivenName(
    $givenName: String
  ) {
    onboardingGivenName(
      action: {
        givenName: $givenName
      }
    ) {
      state {
        ...OnboardingState
      }
    }
  }
  ${ONBOARDING_STATE}
`;

export const GET_THERAPIST_PROFILE = gql`
  query TherapistProfile {
    therapistProfile {
      state {
        ...TherapistProfile
      }
    }
  }
  ${THERAPIST_PROFILE}
`;

export const THERAPIST_PROFILE_SET_LICENSE = gql`
  mutation SetLicense($entries: [SetLicenseEntryInput]) {
    therapistProfileSetLicense(action: { entries: $entries }) {
      state {
        ...TherapistProfile
      }
    }
  }
  ${THERAPIST_PROFILE}
`;

export const THERAPIST_PROFILE_REMOVE_LICENSE = gql`
  mutation RemoveLicense($id: UUID) {
    therapistProfileRemoveLicense(action: { id: $id }) {
      state {
        ...TherapistProfile
      }
    }
  }
  ${THERAPIST_PROFILE}
`;

export const THERAPIST_PROFILE_SET_BIOGRAPHY = gql`
  mutation SetBiography($biography: String) {
    therapistProfileSetBiography(action: { biography: $biography }) {
      state {
        ...TherapistProfile
      }
    }
  }
  ${THERAPIST_PROFILE}
`;

export const THERAPIST_PROFILE_SET_PRACTICE_DETAILS = gql`
  mutation SetPracticeDetails(
    $address: String
    $city: String
    $latitude: Float
    $longitude: Float
    $hideAddress: Boolean
    $phoneNumber: String
    $state: String
    $practiceName: String
    $practiceEmail: String
    $website: String
  ) {
    therapistProfileSetPracticeDetails(
      action: {
        city: $city
        state: $state
        latitude: $latitude
        longitude: $longitude
        practiceName: $practiceName
        practiceEmail: $practiceEmail
        address: $address
        hideAddress: $hideAddress
        phoneNumber: $phoneNumber
        website: $website
      }
    ) {
      state {
        ...TherapistProfile
      }
    }
  }
  ${THERAPIST_PROFILE}
`;

export const THERAPIST_PROFILE_SET_CONTACT_PREFERENCES = gql`
  mutation SetContactPreferences(
    $mobilePhoneNumber: String
    $canText: Boolean
  ) {
    therapistProfileSetContactPreferences(
      action: {
        mobilePhoneNumber: $mobilePhoneNumber
        canText: $canText
      }
    ) {
      state {
        ...TherapistProfile
      }
    }
  }
  ${THERAPIST_PROFILE}
`;

export const THERAPIST_PROFILE_SET_NAME = gql`
  mutation SetName(
    $givenName: String
    $familyName: String
  ) {
    therapistProfileSetName(
      action: {
        givenName: $givenName
        familyName: $familyName
      }
    ) {
      state {
        ...TherapistProfile
      }
    }
  }
  ${THERAPIST_PROFILE}
`;

export const THERAPIST_PROFILE_RESPONSE = gql`
  mutation ProfileResponse($questionCode: String, $responses: [String]) {
    therapistProfileResponse(
      action: { questionCode: $questionCode, responses: $responses }
    ) {
      state {
        ...TherapistProfile
      }
    }
  }
  ${THERAPIST_PROFILE}
`;

export const THERAPIST_PROFILE_SET_PUBLISHABLE = gql`
  mutation SetPublishable($publishable: Boolean) {
    therapistProfileSetPublishable(action: { publishable: $publishable }) {
      state {
        ...TherapistProfile
      }
    }
  }
  ${THERAPIST_PROFILE}
`;
