import React, {useEffect, useState} from "react";
import { Button, Stack, Typography } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import EditProfileCard from "../EditProfileCard/EditProfileCard";
import VerifiedBanner from "../../../../../../components/VerifiedBanner/VerifiedBanner";
import CounselorImageUpload from "../CounselorImageUpload/CounselorImageUpload";
import { deleteProfileImage, uploadImage } from "../../../../../../api/queries";
import CounselorImage from "../../../../../../components/CounselorImage/CounselorImage";
import { IImage } from "../CounselorImageUpload/CounselorImageUpload.types";
import NameInputModal from "../NameInputModal/NameInputModal";

interface ICounselorInformationCardProps {
  givenName: string;
  familyName: string;
  imagePath?: IImage;
}

// TODO - image upload in a hook
function CounselorInformationCard({
  givenName,
  familyName,
  imagePath,
}: ICounselorInformationCardProps) {
  const [openPhotoModal, setOpenPhotoModal] = useState(false);
  const [openNameModal, setOpenNameModal] = useState(false);
  const [hasFirstLastName, setHasFirstLastName] = useState(false);
  const [missingFirstLastName, setMissingFirstLastName] = useState(false);
  const [tryToCloseModal, setTryToCloseModal] = useState(false);

  const handleOpenPhotoModal = () => {
    setOpenPhotoModal(true);
  };
  const handleClosePhotoModal = () => {
    setOpenPhotoModal(false);
  };

  const handleOpenNameModal = () => {
    setOpenNameModal(true);
  }

  useEffect(() => {
    if (givenName && familyName) {
      setHasFirstLastName(true);
    }
  }, [givenName, familyName]);

  useEffect(() => {
    setMissingFirstLastName(!hasFirstLastName);
  }, [hasFirstLastName]);

  useEffect(() => {
    if (hasFirstLastName) {
      setOpenNameModal(false);
    }
    setTryToCloseModal(false);
  }, [tryToCloseModal]);

  const handleCloseNameModal = () => {
    setTryToCloseModal(true);
  }

  useEffect(() => {
    if (!givenName || !familyName) {
      setOpenNameModal(true);
    }
  }, [givenName, familyName]);

  return (
    <EditProfileCard>
      <Stack spacing={1} alignItems="center">
        <div>
          {imagePath && <CounselorImage src={imagePath.path} alt="Profile" />}
          {!imagePath && (
            <AccountCircle sx={{ height: "140px", width: "140px" }} />
          )}
        </div>
        <Typography variant="h5" data-test="therapistName">
          <b>{givenName} {familyName}</b>
        </Typography>
        <VerifiedBanner />
        <Button
          component="label"
          variant="outlined"
          color="primary"
          sx={{ width: "100%", maxWidth: "300px", p: 1 }}
          onClick={handleOpenNameModal}
        >
          Change Name
        </Button>
        <Button
          component="label"
          variant="outlined"
          color="primary"
          sx={{ width: "100%", maxWidth: "300px", p: 1 }}
          onClick={handleOpenPhotoModal}
          data-test="profilePhoto"
        >
          Upload/Remove Photo
        </Button>
      </Stack>
      <NameInputModal
        onClose={handleCloseNameModal}
        open={openNameModal}
        showWelcomeMessage={missingFirstLastName}
      />
      <CounselorImageUpload
        onClose={handleClosePhotoModal}
        open={openPhotoModal}
        onUpload={uploadImage}
        onRemove={deleteProfileImage}
        current={imagePath}
        aspect={1}
      />
    </EditProfileCard>
  );
}

export default CounselorInformationCard;
