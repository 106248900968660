import React from "react";
import {Box, styled, Typography} from "@mui/material";
import RadiusButton from "../../../../../../components/RadiusButton/RadiusButton";

const StyledBox = styled(Box)<{ status?: string }>(({ theme }) => ({
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2),
    width: "100%",
    border: `1px solid ${theme.palette.grey[300]}`,
    minHeight: "80px",
    boxSizing: "border-box"
}));

interface IPartnerCardProps {
  image: string;
  alt: string;
  link: string;
  description1: string;
  description2: string;
  description3: string;
  description4: string;
  buttonDescription: string;
}

function PartnerCard({ image, alt, link, description1, description2, description3,
                       description4, buttonDescription}: IPartnerCardProps) {
    return (
      <StyledBox>
          <a href={link} target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>
              <img src={image} alt={alt} style={{maxWidth: "200px"}} />
          </a>
          <br />
          <Typography variant="h6" component="h1" sx={{color: "#435563", fontWeight: "600"}}>
            {description1}
          </Typography>
          <Typography variant="h5" component="h1" sx={{color: "#435563", fontWeight: "300"}}>
            {description2}
          </Typography>
          <br />
          <Typography variant="subtitle1" component="h1" sx={{color: "#435563"}}>
            {description3}
          </Typography>
          <br />
          <Typography variant="subtitle2" component="h1" sx={{color: "#435563"}}>
            {description4}
          </Typography>
          <br />
          <a href={link} target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>
              <RadiusButton
                variant="contained"
                sx={{
                    bgcolor: "#6BBCB9",
                    p: 2,
                    ":hover": {
                        bgcolor: "#F27269",
                    },
                    maxHeight: "55px",
                    height: "45px",
                    whiteSpace: "nowrap",
                    m: 0.5
                }}
                radius={1.5}>
                {buttonDescription}
              </RadiusButton>
          </a>
      </StyledBox>
    );
}

export default PartnerCard;