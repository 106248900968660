import React from "react";
import { useQuery } from "react-query";
import { Navigate } from "react-router-dom";
import { getProfile } from "../../../../api/queries";
import Error from "../../../../components/Error/Error";
import RedirectToClientApp from "../../../../routers/RedirectToClientApp";

function AccountRedirect() {
  const { isLoading, isError, data } = useQuery(
      "account-profile",
      getProfile,
      {
        cacheTime: 0,
      });

  if (isError) {
    return <Error />;
  }

  if (isLoading) {
    return <div />;
  }

  if (data?.therapist) return <Navigate replace to="/account/therapist" />;

  return <RedirectToClientApp />;
}

export default AccountRedirect;
