import React from "react";
import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import RadiusButton from "../../../../../../components/RadiusButton/RadiusButton";

interface IDeclineDialogProps {
  open: boolean;
  name: string;
  onDecline: () => void;
  onClose: () => void;
}

function DeclineDialog({
  open,
  name,
  onClose,
  onDecline,
}: IDeclineDialogProps) {
  return (
    <Dialog open={open} onClose={onClose} data-test="declineDialog">
      <DialogTitle>Are you sure that you want to decline {name}?</DialogTitle>
      {/* <DialogContent>
        <Typography variant="caption">Reason</Typography>
        <br />
        <Select sx={{ minWidth: "200px" }}>
          <option value="test">option 1</option>
        </Select>
      </DialogContent> */}
      <DialogActions sx={{ color: "grey.500" }}>
        <RadiusButton
          variant="text"
          color="inherit"
          onClick={onClose}
          sx={{ minWidth: "115px", minHeight: "50px" }}
        >
          Close
        </RadiusButton>
        <RadiusButton
          variant="text"
          color="primary"
          onClick={onDecline}
          sx={{ minWidth: "115px", minHeight: "50px" }}
        >
          Decline
        </RadiusButton>
      </DialogActions>
    </Dialog>
  );
}

export default DeclineDialog;
