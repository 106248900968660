import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { contactTherapist } from "../../api/queries";
import { IUseReachOut } from "./useReachOut.types";

function useReachOut(): IUseReachOut {
  const [loadingContact, setLoadingContact] = useState(false);
  const [successContact, setSuccessContact] = useState(false);

  const queryClient = useQueryClient();

  const contactMutation = useMutation(
    ["contact-therapist"],
    ({ therapistId }: { therapistId: string }) => contactTherapist(therapistId),
    {
      onMutate: () => {
        setLoadingContact(true);
        setSuccessContact(false);
      },
      onSuccess: async (_, variables) => {
        setLoadingContact(false);
        setSuccessContact(true);
        // could turn into parameter
        await queryClient.invalidateQueries(`counselors-matched`);
        await queryClient.invalidateQueries(
          `counselorProfile-${variables.therapistId}`
        );
      },
    }
  );

  const handleReset = () => {
    contactMutation.reset();
    setLoadingContact(false);
    setSuccessContact(false);
  };
  return {
    success: successContact,
    loading: loadingContact,
    mutation: contactMutation,
    reset: handleReset,
  };
}

export default useReachOut;
