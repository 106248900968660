import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  Collapse,
  Grid,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import {
  Check,
  Close,
  KeyboardArrowDown,
  KeyboardArrowUp,
  NotificationsOutlined,
} from "@mui/icons-material";
import { useMutation, useQueryClient } from "react-query";
import ConnectionButtons from "../ConnectionButtons/ConnectionButtons";
import {
  ExpirationDate,
  TherapistConnection,
} from "../../../../../../api/queries.types";
import PreferenceDisplay from "../PreferenceDisplay/PreferenceDisplay";
import AcceptDialog from "../AcceptDialog/AcceptDialog";
import DeclineDialog from "../DeclineDialog/DeclineDialog";
import {
  acceptTherapistConnection,
  declineTherapistConnection,
} from "../../../../../../api/queries";
import { formatExpirationDate } from "../../../../../../util/util";

const StyledBox = styled(Box)<{ status?: string }>(({ theme, status }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  width: "100%",
  border: `1px solid ${theme.palette.grey[300]}`,
  minHeight: "80px",
  boxSizing: "border-box",
  borderLeft: `8px solid ${theme.palette.secondary.light}`,
  ...(status === "pending" && {
    borderLeft: `10px solid #9B6799`,
  }),
  ...(status === "accepted" && {
    borderLeft: `10px solid ${theme.palette.primary.dark}`,
  }),
}));

interface IConnectionCardProps {
  connection: TherapistConnection;
}

const expirationWarningThresholdDays = 2;

function ConnectionCard({ connection }: IConnectionCardProps) {
  const [open, setOpen] = useState(false);
  const [acceptDialog, setAcceptDialog] = useState(false);
  const [declineDialog, setDeclineDialog] = useState(false);

  const {
    expiresOn,
    client: { email },
  } = connection;

  const expirationDateObject: ExpirationDate = {
    year: expiresOn[0],
    month: expiresOn[1],
    day: expiresOn[2],
    hour: expiresOn[3],
    minute: expiresOn[4],
    second: expiresOn[5],
  };

  const formattedExpirationDate = formatExpirationDate(expirationDateObject);

  const queryClient = useQueryClient();

  const acceptMutation = useMutation(
    (clientSub: string) => acceptTherapistConnection(clientSub),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("therapistConnections");
        setAcceptDialog(false);
      },
    }
  );
  const declineMutation = useMutation(
    (clientSub: string) => declineTherapistConnection(clientSub),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries("therapistConnections");
        setDeclineDialog(false);
      },
    }
  );

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleAccept = () => {
    acceptMutation.mutate(connection.client.id);
  };

  const handleDecline = () => {
    declineMutation.mutate(connection.client.id);
  };

  const handleAcceptDialogOpen = () => {
    setAcceptDialog(true);
  };

  const handleAcceptDialogClose = () => {
    setAcceptDialog(false);
  };

  const handleDeclineDialogOpen = () => {
    setDeclineDialog(true);
  };

  const handleDeclineDialogClose = () => {
    setDeclineDialog(false);
  };

  const actionItem = (s: string, isClientDeleted: boolean) => {
    switch (s.toLowerCase()) {
      case "pending":
        return (
          <ConnectionButtons
            onConfirm={handleAcceptDialogOpen}
            onDecline={handleDeclineDialogOpen}
            isDisabled={isClientDeleted}
          />
        );
      case "accepted":
        return !isClientDeleted && (
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" color="primary">
              Client Contact Details
            </Typography>
            <hr />
            <Typography sx={{ mt: 1 }} variant="caption" component="p">
              This client is interested in working with you. Reach out to them
              to start the connection.
            </Typography>
            <Typography variant="overline" component="span">
              Email:{" "}
            </Typography>
            <a href={`mailto:${email}`}>{email}</a>
          </Box>
        );
      case "declined":
        return (
          <Box sx={{ p: 2 }}>
            <Typography sx={{ mt: 1 }} variant="caption" component="p">
              This client was interested in working with you. You declined their connection request.
            </Typography>
          </Box>
        );
      default:
        return <div />;
    }
  };

  const indicator = (s: string) => {
    switch (s.toLowerCase()) {
      case "pending":
        return (
          <Typography
            variant="body1"
            sx={{
              verticalAlign: "middle",
              display: "inline-flex",
              alignItems: "center",
              color: "#9B6799",
            }}
            whiteSpace="nowrap"
          >
            <NotificationsOutlined sx={{ mr: 1 }} /> Expires in{" "}
            {formattedExpirationDate.text}
            {(formattedExpirationDate.value.days ?? 5) <
              expirationWarningThresholdDays && (
              <Box sx={{ ml: 2 }}>
                <Chip color="secondary" label="Expires Soon" size="small" />
              </Box>
            )}
          </Typography>
        );
      case "accepted":
        return (
          <Typography
            variant="body1"
            sx={{ verticalAlign: "middle", display: "inline-flex" }}
            color="primary.main"
            whiteSpace="nowrap"
          >
            <Check sx={{ mr: 1 }} /> Accepted
          </Typography>
        );
      case "declined":
        return (
          <Typography
            variant="body1"
            sx={{ verticalAlign: "middle", display: "inline-flex" }}
            color="secondary.main"
            whiteSpace="nowrap"
          >
            <Close sx={{ mr: 1 }} /> Declined
          </Typography>
        )
      default:
        return <div />;
    }
  };

  const fullName = connection.client.deleted
      ? "The client has deleted their account"
      : (connection.client.givenName || "John Doe");
  const mostImportant = connection.clientAttributes.find(
    (c) => c.name === "priority"
  );
  const rest = connection.clientAttributes.filter(
    (c) => c.entries && c.name !== "priority" && c.name !== "prioritySort"
  );

  return (
    <StyledBox status={connection.status.toLowerCase()} data-test="clientCard">
      <Stack spacing={1}>
        <Grid container alignItems="center" justifyContent="center" spacing={4}>
          <Grid item xs>
            <Stack spacing={2}>
              {indicator(connection.status)}
              <Typography variant="h5" component="h3" whiteSpace="nowrap">
                <b>{fullName}</b>
              </Typography>
              {!connection.client.deleted && mostImportant && (
                <PreferenceDisplay
                  title={mostImportant.label}
                  value={mostImportant.entries
                    .map((e) => e.description)
                    .join(", ")}
                />
              )}
            </Stack>
          </Grid>
          <Grid item>{actionItem(connection.status, connection.client.deleted)}</Grid>
        </Grid>
        {!connection.client.deleted && (<Box>
          <Collapse in={open}>
            <Stack spacing={1}>
              {rest.map((r) => (
                <PreferenceDisplay
                  key={r.name}
                  title={r.label ? r.label : "Location"}
                  value={r.entries
                    .map((e) => (e.description ? e.description : e.value))
                    .join(", ")}
                />
              ))}
            </Stack>
          </Collapse>
          <Button
            variant="text"
            color="primary"
            sx={{ maxWidth: "295px" }}
            onClick={handleOpen}
            disableRipple
            endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          >
            {open ? "Hide Preferences" : "View all therapy preferences"}
          </Button>
        </Box>
        )}
      </Stack>
      <AcceptDialog
        open={acceptDialog}
        name={fullName}
        onAccept={handleAccept}
        onClose={handleAcceptDialogClose}
      />
      <DeclineDialog
        open={declineDialog}
        name={fullName}
        onDecline={handleDecline}
        onClose={handleDeclineDialogClose}
      />
    </StyledBox>
  );
}

export default ConnectionCard;
