import { Card, styled } from "@mui/material";

const EditProfileCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  border: `2px solid ${theme.palette.grey[300]}`,
  padding: theme.spacing(2),
  boxShadow: "none",
}));

export default EditProfileCard;
