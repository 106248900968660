import React from "react";
import { Box, Paper, Stack, Typography } from "@mui/material";
import CounselorCarousel from "../CounselorCarousel/CounselorCarousel";
import CounselorSection from "../CounselorSection/CounselorSection";
import SectionAccordion from "../SectionAccordion/SectionAccordion";
import { TherapistData } from "../../../../../../api/queries.types";

interface ICounselorInformationProps {
  counselor: TherapistData;
  onVideoClick: () => void;
}

// TODO - theraputic is spelled therapeutic
function CounselorInformation({
  counselor,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onVideoClick,
}: ICounselorInformationProps) {
  const { biography } = counselor.therapist.therapist;

  const { attributes, featureImages } = counselor.therapist;

  return (
    <Paper elevation={2} sx={{ borderRadius: 4, overflow: "hidden" }}>
      {featureImages && <CounselorCarousel images={featureImages} />}
      <Stack sx={{ p: 6 }}>
        <CounselorSection title="How I Can Help You">
          <Typography variant="body1" data-test="bio">
            {biography ?? <i>Nothing here yet</i>}
          </Typography>
        </CounselorSection>
        <CounselorSection title="About Me">
          <Stack spacing={1}>
            {attributes &&
              attributes
                .filter((a) => a.entries && a.entries.length > 0)
                .map((a) => (
                  <Box key={a.name}>
                    <SectionAccordion title={a.label}>
                      {a.entries ? (
                        a.entries.map((e) => e.description).join(", ")
                      ) : (
                        <i>No entries</i>
                      )}
                    </SectionAccordion>
                  </Box>
                ))}
          </Stack>
        </CounselorSection>
      </Stack>
    </Paper>
  );
}

export default CounselorInformation;
