import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import NavBar from "../../components/NavBar/NavBar";

interface IUnauthenticatedLayoutProps {
  allowLogIn?: boolean;
}

function UnauthenticatedLayout({ allowLogIn }: IUnauthenticatedLayoutProps) {
  return (
    <>
      <section id="nav">
        <NavBar allowLogIn={allowLogIn} />
      </section>
      <Box
        component="main"
        sx={(theme) => ({
          height: `calc(100% - ${theme.mixins.toolbar.minHeight}px - ${theme.mixins.toolbar.minHeight}px)`,
          bgcolor: theme.background,
        })}
      >
        <Outlet />
      </Box>
    </>
  );
}

export default UnauthenticatedLayout;
