import { useCallback, useEffect, useState } from "react";
import { core, usAutocompletePro } from "smartystreets-javascript-sdk";
import { debounce } from "@mui/material";
import { Address } from "../../pages/authenticated/therapist/EditProfile/components/PracticeDetailsForm/PracticeDetailsForm.types";

const { Lookup } = usAutocompletePro;

const key = "120285478463721873";

const clientBuilder = new core.ClientBuilder(new core.SharedCredentials(key));
const client = clientBuilder.buildUsAutocompleteProClient();

function useSmartyAddress() {
  const [input, setInput] = useState("");
  const [list, setList] = useState<Address[]>([]);
  const [error, setError] = useState(false);

  const addressQuery = async (address: string) => {
    const l = new Lookup(address);
    l.maxResults = 10;
    try {
      const result = await client.send(l);
      const smartyResult: Address[] = result.result.flatMap((r) => ({
        street: `${r.streetLine}`,
        city: r.city,
        state: r.state,
        lat: 0,
        long: 0,
      }));
      setList(smartyResult);
    } catch (e) {
      setList([]);
      setError(true);
    }
  };

  const addressLookup = useCallback(debounce(addressQuery, 1000), []);

  const handleInput = (v: string) => {
    setInput(v);
  };

  useEffect(() => {
    if (input.length > 0) {
      (async () => {
        await addressLookup(input);
      })();
    }
  }, [input]);

  return {
    handleInput,
    list,
    error,
  };
}

export default useSmartyAddress;
