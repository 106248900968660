import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useApolloClient, useMutation } from "@apollo/client";
import FormSaveButtons from "../FormSaveButtons/FormSaveButtons";
import {
  RemoveLicense,
  RemoveLicenseVariables,
  SetLicense,
  SetLicenseVariables,
  TherapistProfile,
} from "../../../../../../api/graphql/gql_queries.types";
import {
  GET_THERAPIST_PROFILE,
  THERAPIST_PROFILE_REMOVE_LICENSE,
  THERAPIST_PROFILE_SET_LICENSE,
} from "../../../../../../api/graphql/gql_queries";
import { CancelHandler } from "../ProfileTabs/ProfileTabs.types";
import { usStates } from "../../../../../../util/util";

interface IMultiInputFormProps {
  label: string;
  onCancel?: CancelHandler;
}

function MultiInputForm({ label, onCancel }: IMultiInputFormProps) {
  const client = useApolloClient();

  const therapist = client.readQuery<TherapistProfile>({
    query: GET_THERAPIST_PROFILE,
  });
  const { control, handleSubmit, getValues, setValue } = useForm<{
    values: { state: string; value: string; id: string }[];
  }>({
    defaultValues: {
      values: therapist?.therapistProfile?.state?.therapistLicenses?.map(
        (l) =>
          ({
            value: l!.licenseNumber,
            state: l!.licenseState,
            id: l!.id,
          } as { state: string; value: string; id: string })
      ) ?? [{ value: "", state: "", id: "" }],
    },
  });

  const [titleMutation, { loading, error }] = useMutation<
    SetLicense,
    SetLicenseVariables
  >(THERAPIST_PROFILE_SET_LICENSE, {
    onCompleted: () => {
      if (onCancel) {
        onCancel("Successfully updated your licenses");
      }
    },
    refetchQueries: [{ query: GET_THERAPIST_PROFILE }, "GetProfile"],
  });

  const [removeMutation, { error: removeError }] = useMutation<
    RemoveLicense,
    RemoveLicenseVariables
  >(THERAPIST_PROFILE_REMOVE_LICENSE, {
    refetchQueries: [{ query: GET_THERAPIST_PROFILE }, "GetProfile"],
  });

  const addField = () => {
    const state = getValues();
    setValue("values", [...state.values, { state: "", value: "", id: "" }]);
  };

  const isDuplicateId = (id: string) => {
    if (
      therapist?.therapistProfile?.state?.therapistLicenses?.filter(
        (f) => f?.id === id
      )?.length ??
      -1 > 0
    ) {
      return true;
    }
    return false;
  };
  return (
    <Box>
      <form
        onSubmit={handleSubmit(async (value) => {
          if (value.values.length > 0) {
            await titleMutation({
              variables: {
                entries: value.values
                  .filter((f) => !isDuplicateId(f.id))
                  .map((v) => ({
                    licenseNumber: v.value,
                    licenseState: v.state,
                  })),
              },
            });
          }
        })}
      >
        <Controller
          control={control}
          render={({ field }) => (
            <Stack spacing={2}>
              {field.value.map((f, i) => (
                <FormControl key={f.id} data-test="license-form">
                  <Grid container spacing={1}>
                    <Grid item>
                      <InputLabel id="state-field">State</InputLabel>
                      <Select
                        labelId="state-field"
                        value={f.state}
                        label="State"
                        sx={{ minWidth: "80px" }}
                        data-test="state-selector"
                        onChange={(e) => {
                          const oldValues = [...field.value];
                          oldValues[i].state = e.target.value;
                          field.onChange([...oldValues]);
                        }}
                      >
                        {usStates.map((s) => (
                          <MenuItem key={s.abbreviation} value={s.abbreviation}>
                            {s.abbreviation}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                    <Grid item>
                      <TextField
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={`field-${i}`}
                        {...field}
                        value={f.value}
                        variant="outlined"
                        label={label}
                        sx={{ maxWidth: "280px" }}
                        onChange={(e) => {
                          const oldValues = [...field.value];
                          oldValues[i].value = e.target.value;
                          field.onChange([...oldValues]);
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="remove license"
                                onClick={async () => {
                                  await removeMutation({
                                    variables: {
                                      id: f.id!,
                                    },
                                  });
                                  field.onChange(
                                    field.value.filter(
                                      (d) => d !== field.value[i]
                                    )
                                  );
                                }}
                                edge="end"
                                color="secondary"
                              >
                                <Delete />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              ))}
            </Stack>
          )}
          name="values"
        />
        <Button variant="text" onClick={addField} sx={{ mt: 2 }}>
          Add New
        </Button>
        <FormSaveButtons
          onCancel={onCancel}
          loading={loading}
          error={Boolean(error || removeError)}
        />
      </form>
    </Box>
  );
}

export default MultiInputForm;
