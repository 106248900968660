import React from "react";
import { Outlet } from "react-router-dom";
import { Box, Grid, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import NavBar from "../../components/NavBar/NavBar";
import Navigator from "../../components/Navigator/Navigator";
import { INavItem } from "../../components/Navigator/Navigator.types";

interface IAuthenticatedLayoutProps {
  menu: INavItem[];
  userMenu: any[];
  mobileNavOnly?: boolean;
}

function AuthenticatedLayout({
  menu,
  userMenu,
  mobileNavOnly,
}: IAuthenticatedLayoutProps) {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.down("md"));
  if (match) {
    return (
      <>
        <NavBar mobileNav={<Navigator items={menu} />} userMenu={userMenu} />
        <Toolbar />
        <Box
          component="main"
          sx={(t) => ({
            display: "flex",
            bgcolor: t.background,
          })}
        >
          <Grid container flexDirection="column">
            <Grid item sx={{ flex: 1 }}>
              <Outlet />
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
  return (
    <>
      <NavBar
        nav={!mobileNavOnly ? <Navigator items={menu} /> : undefined}
        userMenu={userMenu}
      />
      <Box
        component="main"
        sx={(t) => ({
          display: "flex",
          height: "calc(100% - 64px)",
          bgcolor: t.background,
        })}
      >
        <Grid container flexDirection="column">
          <Grid item sx={{ flex: 1 }}>
            <Outlet />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default AuthenticatedLayout;
