import React from "react";
import { Box, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NotFoundImage from "../../../assets/404.svg";
import Rain from "../../../assets/rain.svg";
import Logo from "../../../components/Logo/Logo";
import useAuthentication from "../../../hooks/useAuthentication/useAuthentication";
import RadiusButton from "../../../components/RadiusButton/RadiusButton";
import usePageView from "../../../hooks/usePageView/usePageView";

function NotFound() {
  const user = useAuthentication((s) => s.user);
  const navigate = useNavigate();

  const to = user ? "/account" : "/";

  usePageView({ path: "/app/not-found" });

  return (
    <section>
      <Box sx={{ textAlign: "center" }}>
        <Logo />
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Stack spacing={1}>
          <img src={Rain} alt="Not Found" height="140px" />
          <img src={NotFoundImage} alt="Not Found" height="160px" />
          <Stack
            direction="row"
            justifyContent="center"
            spacing={2}
            sx={{ pt: 4 }}
          >
            <RadiusButton
              disableElevation
              onClick={() => navigate(to)}
              variant="contained"
              color="primary"
              radius={4}
              sx={{
                p: 2,
                pl: 6,
                pr: 6,
              }}
            >
              Home
            </RadiusButton>
          </Stack>
        </Stack>
      </Box>
    </section>
  );
}

export default NotFound;
