import React, { useState } from "react";

interface IUseFileSelectExport {
  imgSrc: string;
  onSelectFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickImage: (src: string) => void;
  onClose: () => void;
}

function useFileSelect(): IUseFileSelectExport {
  const [imgSrc, setImgSrc] = useState("");

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() ?? "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const handleClickImage = (src: string) => {
    setImgSrc(src);
  };
  const handleClose = () => {
    setImgSrc("");
  };

  return {
    imgSrc,
    onSelectFile: handleSelectFile,
    onClose: handleClose,
    onClickImage: handleClickImage,
  };
}

export default useFileSelect;
