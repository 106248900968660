import React from "react";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import {Controller, useForm} from "react-hook-form";
import {Box, Stack, TextField} from "@mui/material";
import {useApolloClient, useMutation} from "@apollo/client";
import {INameInputForm} from "./NameInputForm.types";
import {GET_THERAPIST_PROFILE, THERAPIST_PROFILE_SET_NAME} from "../../../../../../api/graphql/gql_queries";
import {SetName, SetNameVariables, TherapistProfile} from "../../../../../../api/graphql/gql_queries.types";
import {CancelHandler} from "../ProfileTabs/ProfileTabs.types";
import FormSaveButtons from "../FormSaveButtons/FormSaveButtons";

const schema = yup.object({
  givenName: yup.string()
    .max(16, "First Name must be less than 16 characters")
    .min(1, "First Name must be longer than 1 character")
    .required("First Name is required"),
  familyName: yup.string()
    .max(16, "Last Name must be less than 16 characters")
    .min(1, "Last Name must be longer than 1 character")
    .required("Last Name is required"),
});
interface INameInputFormProps {
  onCancel?: CancelHandler;
  onClose?: (event?: any, message?: string) => void;
}

function NameInputForm({ onCancel, onClose }: INameInputFormProps) {
  const client = useApolloClient();
  const therapist = client.readQuery<TherapistProfile>({
    query: GET_THERAPIST_PROFILE,
  });

  const handleClose = () => {
    if (onCancel) {
      onCancel("Successfully updated your name");
    }
    if (onClose) {
      onClose();
    }
  }

  const [nameMutation, {loading, error}] = useMutation<
    SetName,
    SetNameVariables
  >(THERAPIST_PROFILE_SET_NAME, {
    onCompleted: () => {
      handleClose();
    },
    refetchQueries: [{query: GET_THERAPIST_PROFILE}, "GetProfile"],
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<INameInputForm>({
    defaultValues: {
      givenName: therapist?.therapistProfile!.state!.therapist!.givenName ?? "",
      familyName: therapist?.therapistProfile!.state!.therapist!.familyName ?? "",
    },
    resolver: yupResolver(schema),
  });

  return (
    <Box>
      <form
        onSubmit={handleSubmit(async(values) => {
          await nameMutation({
            variables: {
              givenName: values.givenName,
              familyName: values.familyName,
            },
          });
          handleClose();
        })}
      >
        <Stack spacing={1} alignItems="center">
          <Stack spacing={2}>
            <Controller
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  label="First Name"
                  error={Boolean(errors.givenName)}
                  helperText={errors.givenName?.message}
                  autoFocus
                  />
              )}
              name="givenName"
              />
            <Controller
              control={control}
              render={({field}) => (
                <TextField
                  {...field}
                  label="Last Name"
                  error={Boolean(errors.familyName)}
                  helperText={errors.familyName?.message}
                />
              )}
              name="familyName"
            />
          </Stack>
        </Stack>
        <FormSaveButtons
          // onCancel={onCancel}
          onCancel={handleClose}
          loading={loading}
          error={Boolean(error)}
        />
      </form>
    </Box>
  );
}

export default NameInputForm;