import React, { useRef } from "react";
import { Box, Button, styled } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { FeatureImage } from "../../../../../../api/queries.types";
import { useClientConfig } from "../../../../../../api/queries";

const NextButton = styled(Button)<{ left?: boolean }>(({ theme, left }) => ({
  position: "absolute",
  height: "50px",
  width: "50px",
  top: "calc(50% - 25px)",
  transition: "opacity 0.2s",
  background: theme.palette.grey[200],
  opacity: 0.7,
  zIndex: 999,
  "&:hover": {
    background: theme.palette.grey[200],
    opacity: 0.8,
  },
  ...(left ? { left: "12px" } : { right: "12px" }),
  [theme.breakpoints.down("sm")]: {
    height: "32px",
    width: "32px",
  },
}));

const CarouselContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "relative",
  zIndex: 0,
  overflow: "hidden",
  height: "180px",
  [theme.breakpoints.up("md")]: {
    height: "300px",
  },
}));

const CarouselSlide = styled(Box)(() => ({
  position: "relative",
  display: "flex",
  overflowX: "scroll",
  overflowY: "hidden",
  height: "100%",
  scrollSnapType: "x mandatory",
  scrollBehavior: "smooth",
  "-ms-overflow-style": "none",
  scrollbarWidth: "none",
  "-webkit-scrollbar": {
    display: "none",
  },
  "&::-webkit-scrollbar": {
    display: "none" /* for Chrome, Safari, and Opera */,
  },
  "& > div": {
    zIndex: 1,
    scrollSnapAlign: "start",
    flexShrink: 0,
    width: "100%",
    height: "100%",
    transformOrigin: "center center",
    transform: "scale(1)",
    transition: "transform 0.5s",
    display: "flex",
    verticalAlign: "middle",
  },
}));

const ImageContainer = styled("span")(() => ({
  "& > img": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
  },
}));

interface ICounselorCarouselProps {
  images: FeatureImage[];
}

// TODO - pass images / make functional
function CounselorCarousel({ images }: ICounselorCarouselProps) {
  const ref = useRef<HTMLDivElement>();
  const { config } = useClientConfig();

  const assetsUrl = config!.find((c) => c.name === "assets.site")!.value;
  const handleScrollRight = () => {
    if (ref.current) {
      const max = (ref.current.childElementCount - 1) * ref.current.clientWidth;
      ref.current.scrollLeft += ref.current.clientWidth;
      if (ref.current.scrollLeft >= max) {
        ref.current.scrollLeft = 0;
      }
    }
  };
  const handleScrollLeft = () => {
    if (ref.current) {
      const max = (ref.current.childElementCount - 1) * ref.current.clientWidth;
      ref.current.scrollLeft -= ref.current.clientWidth;
      if (ref.current.scrollLeft === 0) {
        ref.current.scrollLeft = max;
      }
    }
  };
  return (
    <CarouselContainer>
      <NextButton left onClick={handleScrollLeft}>
        <ChevronLeft />
      </NextButton>
      <NextButton onClick={handleScrollRight}>
        <ChevronRight />
      </NextButton>
      <CarouselSlide ref={ref}>
        {images.map((image) => (
          <div key={image.id}>
            <ImageContainer>
              <img
                src={`${assetsUrl}${image.path}`}
                alt={`img-${image.path}`}
                width="100%"
              />
            </ImageContainer>
          </div>
        ))}
      </CarouselSlide>
    </CarouselContainer>
  );
}

export default CounselorCarousel;
