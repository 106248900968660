import { styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const RadiusButton = styled(LoadingButton)<{ radius?: number; blur?: boolean }>(
  ({ theme, radius = 2, blur = false }) => ({
    borderRadius: theme.spacing(radius),
    fontWeight: "bold",
    fontSize: "0.8rem",
    ...(blur && {
      textShadow: "0 0 6px rgb(0 0 0 / 68%) !important",
      color: "transparent",
      "&.Mui-disabled": {
        ...theme.variables.blur,
        "&.MuiButton-containedSecondary": {
          background: theme.palette.grey["400"],
          textShadow: "0 0 6px rgb(255 255 255 / 68%) !important",
        },
      },
    }),
  })
);

export default RadiusButton;
