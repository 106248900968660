import { SetState, State, StateCreator } from "zustand";
import { Draft, produce } from "immer";
import {
  OnboardingAnswerVariables,
  OnboardingState,
  OnboardingState_currentQuestion_options,
} from "../../../api/graphql/gql_queries.types";

export const immer =
  <T extends State>(
    config: StateCreator<T, (fn: (draft: Draft<T>) => void) => void>
  ): StateCreator<T> =>
  (set, get, api) =>
    config((fn) => set(produce<T>(fn)), get, api);

export type StoreSlice<T extends object, E extends object = T> = (
  set: SetState<E extends T ? E : E & T>
) => T;

export type IAttributes =
  | (OnboardingState_currentQuestion_options | null)[]
  | null
  | undefined;

export type Handler = (() => Promise<void>) | (() => void) | null;

export type NextHandler = () => void;

export interface IStep {
  state: OnboardingState | null;
  onVariables: (variables: OnboardingAnswerVariables) => void;
  onValidate: (v: boolean) => void;
}
