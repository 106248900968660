import React from "react";
import { Box, SxProps, Theme, Typography } from "@mui/material";
import { Check } from "@mui/icons-material";

interface IVerifiedBannerProps {
  sx?: SxProps<Theme>;
}

function VerifiedBanner({ sx = [] }: IVerifiedBannerProps) {
  return (
    <Box
      sx={[
        {
          display: "flex",
          alignItems: "center",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Check
        sx={{
          fontSize: "1.2rem",
          color: "primary.main",
          mr: 1,
        }}
      />
      <Typography
        variant="subtitle2"
        sx={{ textWrap: "nowrap", color: "primary.main", fontWeight: "600" }}
      >
        Verified by Alli Connect
      </Typography>
    </Box>
  );
}

export default VerifiedBanner;
