import {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import useAuthentication from "../../hooks/useAuthentication/useAuthentication";
import useEventLog from "../../hooks/useEventLog/useEventLog";

interface IRequireAuthenticationProps {
  children: JSX.Element;
}

function RequireAuthentication({ children }: IRequireAuthenticationProps): JSX.Element | null {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const setUser = useAuthentication((s) => s.setUser);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const {
    mutation: eventLogMutation
  } = useEventLog();

  const handleEventLog = (sub: string) => {
    eventLogMutation.mutate({ sub, event: "LOGIN" });
  };

  useEffect(() => {
    (async () => {
      try {
        if (isAuthenticated && user) {
          const token = await getAccessTokenSilently();
          setUser({
            sub: user.thrive_sub ?? null,
            accessToken: token ?? null,
          });
          handleEventLog(user.thrive_sub);
          setLoading(false);
        }
      } catch (e) {
        setError(true);
      }
    })();
  }, [isAuthenticated]);

  if (error) throw new Error();

  if (loading) return null;

  if (isAuthenticated) return children;

  return null;
}

export default RequireAuthentication;
