import React, {useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";
import usePageView from "../../../../hooks/usePageView/usePageView";
import Error from "../../../../components/Error/Error";
import useAuthentication from "../../../../hooks/useAuthentication/useAuthentication";
import Loading from "../../../../components/Loading/Loading";

function TherapistRegistration() {
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const {user, isAuthenticated, loginWithRedirect, getAccessTokenSilently, isLoading} = useAuth0();
  const setUser = useAuthentication((s) => s.setUser);

  useEffect(() => {
    (async() => {
      try {
        if (!isLoading) {
          if (isAuthenticated) {
            await getAccessTokenSilently();
          } else {
            await loginWithRedirect({
              redirectUri: `https://${window.location.host}/therapist/register`
            });
          }
        }
      } catch (e) {
        setError(true);
      }

    })();
  }, [isLoading, isAuthenticated]);

  useEffect(() => {
    (async () => {
      try {
        if (isAuthenticated && user) {
          const token = await getAccessTokenSilently();

          setUser({
            sub: user.thrive_sub ?? null,
            accessToken: token ?? null,
          });

          navigate("/account/therapist/edit-profile");
        }
      } catch (e) {
        setError(true);
      }
    })();
  }, [isAuthenticated]);

  usePageView({path: "/app/therapist/registration"});

  if (error) {
    return <Error centered />;
  }

  return <Loading />;
}

export default TherapistRegistration;
