import { Box, styled } from "@mui/material";
import WaveSVG from "../../assets/wave.svg";

const WaveBackground = styled(Box)(({ theme }) => ({
  backgroundImage: `url(${WaveSVG})`,
  backgroundRepeat: "no-repeat",
  backgroundPositionY: "60px",
  backgroundSize: "cover",
  height: "100%",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    backgroundPositionY: "50px",
  },
  [theme.breakpoints.up("xl")]: {
    backgroundPositionY: "20px",
  },
}));

export default WaveBackground;
