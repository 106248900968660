import React from "react";
import { Box, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import FormSaveButtons from "../FormSaveButtons/FormSaveButtons";
import {
  SetBiography,
  SetBiographyVariables,
  TherapistProfile,
} from "../../../../../../api/graphql/gql_queries.types";
import {
  GET_THERAPIST_PROFILE,
  THERAPIST_PROFILE_SET_BIOGRAPHY,
} from "../../../../../../api/graphql/gql_queries";
import { CancelHandler } from "../ProfileTabs/ProfileTabs.types";

interface IFreeResponseFormProps {
  label: string;
  onCancel?: CancelHandler;
}

// TODO - function to get data instead of just biography
function FreeResponseForm({ label, onCancel }: IFreeResponseFormProps) {
  const client = useApolloClient();
  const therapist = client.readQuery<TherapistProfile>({
    query: GET_THERAPIST_PROFILE,
  });

  const [bioMutation, { loading, error }] = useMutation<
    SetBiography,
    SetBiographyVariables
  >(THERAPIST_PROFILE_SET_BIOGRAPHY, {
    onCompleted: () => {
      if (onCancel) {
        onCancel("Successfully updated your biography");
      }
    },
    refetchQueries: [{ query: GET_THERAPIST_PROFILE }, "GetProfile"],
  });

  const { control, handleSubmit } = useForm<{ value: string | null }>({
    defaultValues: {
      value: therapist?.therapistProfile!.state!.therapist!.biography,
    },
  });
  return (
    <Box>
      <form
        onSubmit={handleSubmit(async (values) => {
          await bioMutation({
            variables: {
              biography: values.value,
            },
          });
        })}
      >
        <Controller
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="filled"
              label={label}
              multiline
              minRows={8}
              fullWidth
            />
          )}
          name="value"
        />
        <FormSaveButtons
          onCancel={onCancel}
          loading={loading}
          error={Boolean(error)}
        />
      </form>
    </Box>
  );
}

export default FreeResponseForm;
