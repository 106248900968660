import React, { useEffect, useRef, useState } from "react";
import {
  AlertColor,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Modal,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { DeleteOutlined, Done, SaveOutlined } from "@mui/icons-material";
import { useMutation } from "react-query";
import { PixelCrop } from "react-image-crop";
import useFileSelect from "../../../../../../hooks/useFileSelect/useFileSelect";
import { getCroppedImg, imgToCanvas } from "../../../../../../util/util";
import { IImage } from "./CounselorImageUpload.types";
import useTherapistData from "../../../../../../hooks/useTherapistData/useTherapistData";
import { useClientConfig } from "../../../../../../api/queries";
import Toast from "../../../../../../components/Toast/Toast";
import CroppableImage from "../CroppableImage/CroppableImage";

const StyledImage = styled("img")(({ theme }) => ({
  maxWidth: "400px",
  maxHeight: "400px",
  [theme.breakpoints.up("md")]: {
    maxHeight: "600px ",
    maxWidth: "600px ",
  },
}));

interface ICounselorImageUploadProps {
  open: boolean;
  onClose: () => void;
  onUpload: (image: Blob) => Promise<{ path: string }>;
  onRemove: (id: string) => Promise<void>;
  multiple?: IImage[];
  current?: IImage;
  aspect?: number;
}

// TODO - refactor
function CounselorImageUpload({
  open,
  onClose: onModalClose,
  onRemove,
  onUpload,
  multiple,
  current,
  aspect = 16 / 9,
}: ICounselorImageUploadProps) {
  const { imgSrc, onSelectFile, onClickImage } = useFileSelect();
  const { refetch } = useTherapistData();
  const imgRef = useRef<HTMLImageElement>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const { config } = useClientConfig();
  const [status, setStatus] = useState<{
    variant: AlertColor;
    message: string;
  }>({ variant: "success", message: "" });

  const assetsUrl = config!.find((c) => c.name === "assets.site")!.value;

  // close modal and reset data
  const handleClose = () => {
    onClickImage("");
    setSelectedId(null);
    setStatus({ variant: "success", message: "" });
    onModalClose();
  };

  const uploadMutation = useMutation(onUpload, {
    onSuccess: async () => {
      if (!multiple) {
        handleClose();
      } else {
        setStatus({ variant: "success", message: "Photo was updated" });
      }
      await refetch();
    },
    onError: () => {
      setStatus({ variant: "error", message: "There was an unexpected error" });
    },
  });

  const removeMutation = useMutation(onRemove, {
    onSuccess: async () => {
      onClickImage("");
      setSelectedId(null);
      setStatus({ variant: "success", message: "Photo was removed" });
      await refetch();
    },
    onError: () => {
      setStatus({ variant: "error", message: "There was an unexpected error" });
    },
  });

  // set the croppable image if one exists currently on render
  useEffect(() => {
    if (open) {
      if (current) {
        setSelectedId(current.id);
        onClickImage(`${assetsUrl}${current.path}`);
      }
      if (multiple && multiple.length > 0) {
        setSelectedId(multiple[0].id);
        onClickImage(`${assetsUrl}${multiple[0].path}`);
      }
    }
  }, [current, multiple, open]);

  // upload image with respective query
  const handleUpload = async () => {
    if (imgSrc) {
      const img = new Image();
      img.src = imgSrc;
      let imgCropped = null;
      if (selectedId) {
        imgCropped = await imgToCanvas(img);
      } else if (completedCrop && imgRef.current) {
        imgCropped = await getCroppedImg(imgRef.current, completedCrop);
      }
      if (imgCropped) {
        uploadMutation.mutate(imgCropped);
      }
    }
  };
  // select new image from side bar when multiple pictures present
  const handleClickImage = (image: IImage) => {
    setSelectedId(image.id);
    onClickImage(`${assetsUrl}${image.path}`);
  };

  const handleRemove = () => {
    if (selectedId) {
      removeMutation.mutate(selectedId);
    }
  };

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelectFile(e);
    setSelectedId(null);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          p: 2,
          minWidth: "300px",
          maxHeight: "100vh",
          overflowY: "scroll",
        }}
      >
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item>
            <Stack spacing={2} alignItems="center">
              <Typography variant="h6" color="grey.800">
                <b>
                  {multiple
                    ? "Select/Edit your Profile Carousel Images"
                    : "Select/Edit your Profile Image"}
                </b>
              </Typography>
              <Divider sx={{ width: "100%", borderColor: "grey.300" }} />
              {imgSrc && !selectedId && (
                <Box sx={{ mt: 2 }}>
                  <CroppableImage
                    imgRef={imgRef}
                    imgSrc={imgSrc}
                    aspect={aspect}
                    onCompleteCrop={(c) => setCompletedCrop(c)}
                  />
                </Box>
              )}
              {imgSrc && selectedId && (
                <Box sx={{ mt: 2 }}>
                  <StyledImage src={imgSrc} alt="picture" />
                </Box>
              )}
              <div>
                <Grid
                  container
                  spacing={1}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <Button
                      component="label"
                      variant="outlined"
                      color="primary"
                      sx={{ width: "100%", p: 1 }}
                    >
                      Choose a File
                      <input type="file" hidden onChange={handleSelectFile} />
                    </Button>
                  </Grid>
                  {imgSrc && (
                    <>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          endIcon={<SaveOutlined />}
                          onClick={handleUpload}
                          disabled={Boolean(!imgSrc || selectedId)}
                        >
                          Save
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="error"
                          endIcon={<DeleteOutlined />}
                          onClick={handleRemove}
                          disabled={!selectedId}
                        >
                          Remove
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={handleClose}
                          endIcon={<Done />}
                        >
                          Done
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </div>
            </Stack>
          </Grid>
          {multiple && (
            <Grid item>
              <Stack spacing={1}>
                <Typography variant="overline">Existing Images</Typography>
                {multiple.map((m) => (
                  <Box
                    key={m.id}
                    sx={{
                      transition: "opacity 0.2s",
                      "& > img": {
                        minWidth: "120px",
                        maxHeight: "120px",
                        objectFit: "contain",
                      },
                      cursor: "pointer",
                      "&: hover": {
                        opacity: "0.8",
                      },
                    }}
                    onClick={() => handleClickImage(m)}
                  >
                    <img
                      src={`${assetsUrl}${m.path}`}
                      alt={`Additional-${m}`}
                    />
                  </Box>
                ))}
              </Stack>
            </Grid>
          )}
        </Grid>
        <Toast
          variant={status.variant}
          text={status.message}
          open={status.message !== ""}
          onClose={() => setStatus({ variant: "success", message: "" })}
        />
      </Card>
    </Modal>
  );
}

export default CounselorImageUpload;
