import React from "react";
import { styled } from "@mui/material";
import { useClientConfig } from "../../api/queries";

const CounselorImageStyled = styled("img")<{}>(
  ({ theme }) => ({
    borderRadius: theme.spacing(4),
    height: "100%",
    width: "100%",
    minHeight: "120px",
    minWidth: "120px",
    maxHeight: "160px",
    maxWidth: "160px",
    objectFit: "contain",
  })
);

interface ICounselorImageProps {
  alt: string;
  src: string;
}

function CounselorImage({ src, alt }: ICounselorImageProps) {
  const { config } = useClientConfig();
  if (!config) return null;
  const url = config.find((c) => c.name === "assets.site")!.value;
  return <CounselorImageStyled src={`${url}${src}`} alt={alt} />;
}

export default CounselorImage;
