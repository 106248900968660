import {withAuthenticationRequired} from "@auth0/auth0-react";
import React from "react";
import RequireAuthentication from "../RequireAuthentication/RequireAuthentication";

interface IAuth0WrapperProps {
  children: JSX.Element;
}

function Auth0Wrapper({ children }: IAuth0WrapperProps): JSX.Element | null {
  return (<RequireAuthentication>
            {children}
          </RequireAuthentication>);
}

export default withAuthenticationRequired(Auth0Wrapper, {});
