import React from "react";
import { Box } from "@mui/material";
import RadiusButton from "../../../../../../components/RadiusButton/RadiusButton";
import Toast from "../../../../../../components/Toast/Toast";

interface IFormSaveButtonsProps {
  onCancel?: () => void;
  loading?: boolean;
  error?: boolean;
}

function FormSaveButtons({ onCancel, loading, error }: IFormSaveButtonsProps) {
  return (
    <Box sx={{ mt: 2 }}>
      <RadiusButton
        radius={1}
        variant="contained"
        color="primary"
        disableElevation
        type="submit"
        sx={{ p: 1, pl: 3, pr: 3, mr: 2 }}
        loading={loading}
      >
        Save
      </RadiusButton>
      <RadiusButton
        radius={1}
        variant="text"
        onClick={onCancel ? () => onCancel() : () => {}}
        sx={{ p: 1, pl: 3, pr: 3 }}
      >
        Close
      </RadiusButton>
      <Toast
        variant="error"
        text="There was an error with your request."
        open={Boolean(error)}
      />
    </Box>
  );
}

export default FormSaveButtons;
