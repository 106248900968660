import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import Rain from "../../assets/rain.svg";
import RadiusButton from "../RadiusButton/RadiusButton";

interface IErrorProps {
  centered?: boolean;
}

function Error({ centered }: IErrorProps) {
  const handleRefresh = () => {
    window.location.reload();
  };
  return (
    <Box sx={centered ? { margin: "0 auto", textAlign: "center" } : {}}>
      <Stack spacing={2} alignItems="center">
        <img src={Rain} alt="Not Found" height="140px" />
        <Typography variant="h5" color="secondary" sx={{ maxWidth: "480px" }}>
          <b>Unfortunately, something went wrong.</b>
        </Typography>
        <RadiusButton
          radius={3}
          variant="contained"
          color="secondary"
          disableElevation
          onClick={handleRefresh}
        >
          Refresh your Browser
        </RadiusButton>
      </Stack>
    </Box>
  );
}

export default Error;
