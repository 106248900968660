import React, { useState } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
  Toolbar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import logo from "../../assets/logo.svg";
import useAuthentication from "../../hooks/useAuthentication/useAuthentication";

interface INavBarProps {
  mobileNav?: React.ReactElement;
  nav?: React.ReactElement;
  userMenu?: any[];
  allowLogIn?: boolean;
}

function NavBar({ mobileNav, nav, userMenu, allowLogIn }: INavBarProps) {
  const [anchor, setAnchor] = useState<null | HTMLButtonElement>(null);
  const { user } = useAuthentication((state) => ({
    user: state.user,
    setUser: state.setUser,
  }));
  const navigate = useNavigate();

  const handleOpenPopover = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(e.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchor(null);
  };

  const handleNav = (to: string) => {
    handleClosePopover();
    navigate(to);
  };

  const open = Boolean(anchor);

  const popover = userMenu ? (
    <Popover
      open={open}
      anchorEl={anchor}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box sx={{ minWidth: "180px" }}>
        <List>
          {userMenu.map((m) => (
            <ListItem disablePadding key={m.label}>
              <ListItemButton onClick={() => handleNav(m.to)}>
                <ListItemText primary={m.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Popover>
  ) : null;

  if (user) {
    return (
      <>
        <AppBar position="fixed" sx={{ bgcolor: "white", zIndex: 4 }}>
          <Toolbar>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item>
                <Box
                  component="a"
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => navigate("/account")}
                >
                  <img src={logo} alt="Alli Connect" height="48px" />
                </Box>
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={2}>
                  {nav}
                  <IconButton data-test="userMenu" onClick={handleOpenPopover}>
                     <Avatar sx={{ bgcolor: "primary.light" }}>
                       <ManageAccountsIcon />
                     </Avatar>
                  </IconButton>
                </Stack>
                {popover}
              </Grid>
            </Grid>
          </Toolbar>
          {mobileNav && <Toolbar>{mobileNav}</Toolbar>}
        </AppBar>
        <Toolbar />
      </>
    );
  }
  return (
    <>
      <AppBar position="fixed" sx={{ bgcolor: "white", zIndex: 4 }}>
        <Toolbar sx={{ position: "relative" }}>
          <Grid container justifyContent="center">
            <Grid item>
              <Box
                component="a"
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => navigate("/")}
              >
                <img src={logo} alt="Alli Connect" height="48px" />
              </Box>
            </Grid>
          </Grid>
          {allowLogIn && (
            <Button
              variant="text"
              color="primary"
              sx={{ whiteSpace: "nowrap", position: "absolute", right: "2%" }}
              onClick={() => navigate("/account")}
            >
              Log In
            </Button>
          )}
        </Toolbar>
        {nav && <Toolbar>{nav}</Toolbar>}
      </AppBar>
      <Toolbar />
    </>
  );
}

export default NavBar;
