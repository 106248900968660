import React from "react";
import { Alert, AlertColor, Slide, Snackbar } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

interface IToastProps {
  variant: AlertColor;
  text: string;
  open: boolean;
  onClose?: () => void;
}

function TransitionLeft(
  props: TransitionProps & { children: React.ReactElement<any, any> }
) {
  return <Slide {...props} direction="left" />;
}

function Toast({ variant, text, open, onClose }: IToastProps) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      onClose={onClose}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      TransitionComponent={TransitionLeft}
    >
      <Alert severity={variant} variant="filled" sx={{ width: "100%" }}>
        {text}
      </Alert>
    </Snackbar>
  );
}

export default Toast;
