import React, { useEffect, useState } from "react";

interface IUseScrollTransitionProps {
  element: React.MutableRefObject<any>;
  offset?: number;
}

function useScrollTransition({
  element,
  offset = 0,
}: IUseScrollTransitionProps) {
  const [onScreen, setOnScreen] = useState(true);

  const handleScroll = () => {
    if (element && element.current) {
      const rect = element.current.getBoundingClientRect();
      // Off screen
      if (rect.y + rect.height < offset || rect.y > window.innerHeight) {
        if (onScreen) {
          setOnScreen(false);
        }
      } else {
        setOnScreen(true);
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);
  return onScreen;
}

export default useScrollTransition;
