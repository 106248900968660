import React from "react";
import {Box, FormControlLabel, Stack, Switch, TextField, Tooltip} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useApolloClient, useMutation } from "@apollo/client";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import FormSaveButtons from "../FormSaveButtons/FormSaveButtons";
import {
  SetContactPreferences,
  SetContactPreferencesVariables,
  TherapistProfile,
} from "../../../../../../api/graphql/gql_queries.types";
import {
  GET_THERAPIST_PROFILE,
  THERAPIST_PROFILE_SET_CONTACT_PREFERENCES
} from "../../../../../../api/graphql/gql_queries";
import { CancelHandler } from "../ProfileTabs/ProfileTabs.types";
import {IContactPreferencesForm} from "./ContactPreferencesForm.types";

const schema = yup.object({
  mobilePhoneNumber: yup
    .string()
    .notRequired()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, "Phone number must be in a valid format")
    .max(10, "Phone number must be in a valid format")
    .nullable()
    .transform((v) => v || null),
  canText: yup
    .boolean()
    .required(),
})

interface IContactPreferencesFormProps {
  label: string;
  onCancel?: CancelHandler;
}

// TODO - function to get data instead of just mobile phone number
function ContactPreferencesForm({ label, onCancel }: IContactPreferencesFormProps) {
  const client = useApolloClient();
  const therapist = client.readQuery<TherapistProfile>({
    query: GET_THERAPIST_PROFILE,
  });

  const [contactPreferencesMutation, { loading, error }] = useMutation<
    SetContactPreferences,
    SetContactPreferencesVariables
  >(THERAPIST_PROFILE_SET_CONTACT_PREFERENCES, {
    onCompleted: () => {
      if (onCancel) {
        onCancel("Successfully updated your contact preferences");
      }
    },
    refetchQueries: [{ query: GET_THERAPIST_PROFILE }, "GetProfile"],
  });

  const { control, handleSubmit,
            formState: { errors }} = useForm<IContactPreferencesForm>({
    defaultValues: {
      mobilePhoneNumber: therapist?.therapistProfile!.state!.therapist!.mobilePhoneNumber,
      canText: therapist?.therapistProfile!.state!.therapist!.canText,
    },
    resolver: yupResolver(schema),
  });
  return (
    <Box>
      <form
        onSubmit={handleSubmit(async (values) => {
          await contactPreferencesMutation({
            variables: {
              mobilePhoneNumber: values.mobilePhoneNumber,
              canText: values.canText,
            },
          });
        })}
      >
        <Stack direction="row" spacing={2} style={{alignItems: "center"}}>
        <Controller
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="filled"
              label={label}
              error={Boolean(errors.mobilePhoneNumber)}
              helperText={errors.mobilePhoneNumber?.message}
            />
          )}
          name="mobilePhoneNumber"
        />
        <Controller
          control={control}
          render={({ field }) => (
            <Tooltip
            title="Text START or STOP to (425) 786-9948 to change preferences">
            <FormControlLabel
              control={
                <Switch
                  disabled
                  checked={field.value ?? false}
                  onChange={(e) => {
                    field.onChange(e.target.checked);
                  }}
                />
              }
              label="Receive notifications via text"
            />
            </Tooltip>
          )}
          name="canText"
        />
        </Stack>
        <FormSaveButtons
          onCancel={onCancel}
          loading={loading}
          error={Boolean(error)}
        />
      </form>
    </Box>
  );
}

export default ContactPreferencesForm;
