import React, {useEffect} from "react";
import {useAuth0} from "@auth0/auth0-react";
import useAuthentication from "../../../hooks/useAuthentication/useAuthentication";
import {clearSessionCookie} from "../../../api/queries";
import usePageView from "../../../hooks/usePageView/usePageView";

interface ILogoutProps {
  to: string;
}

function Logout({to}: ILogoutProps) {
  const { setUser } = useAuthentication();
  const { logout } = useAuth0();

  usePageView({ path: "/app/logout" });

  useEffect(() => {
    (async () => {
      await clearSessionCookie();
      let redirectUrl = `https://${window.location.host}${to}`;
      if(to.includes("http")) {
        redirectUrl = to;
      }
      logout({returnTo: redirectUrl});
      setUser(null);
      sessionStorage.clear();
      localStorage.clear();
    })();
  }, []);
  return <div />;
}

export default Logout;
