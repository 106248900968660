import React, {useLayoutEffect, useState} from "react";
import "./App.scss";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import ReactGA from "react-ga4";
import theme from "./theme/Theme";
import GeneralRouter from "./routers/GeneralRouter/GeneralRouter";
import TherapistRouter from "./routers/TherapistRouter/TherapistRouter";
import client from "./api/graphql/client";
import Loading from "./components/Loading/Loading";
import { useBootStore, useClientConfig } from "./api/queries";
import Error from "./components/Error/Error";
import AccountRedirect from "./pages/authenticated/common/AccountRedirect/AccountRedirect";
import AuthProvider from "./components/AuthProvider/AuthProvider";
import Auth0Wrapper from "./components/Auth0Wrapper/Auth0Wrapper";
import RedirectToClientApp from "./routers/RedirectToClientApp";

function App() {
  const {boot, getBoot} = useBootStore();
  const {config, getConfig} = useClientConfig();
  const [error, setError] = useState(false);

  useLayoutEffect(() => {
    (async () => {
      try {
        await getBoot();
        await getConfig();
      } catch {
        setError(true);
      }
    })();
  }, []);

  useLayoutEffect(() => {
    if (boot && config) {
      const measurementId =
        config.find((c) => c.name === "google.analytics")?.value ?? "";
      if (measurementId) {
        ReactGA.initialize(measurementId);
      }
    }
  }, [boot, config]);

  if (error) {
    return (
        <ThemeProvider theme={theme}>
          <Error/>
        </ThemeProvider>
    );
  }

  if (!boot || !config) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider config={config}>
        <ApolloProvider client={client(boot.apiUrl)}>
          <Router>
            <Routes>
              <Route path="/*" element={<GeneralRouter />} />
              <Route
                path="/account"
                element={
                  <Auth0Wrapper>
                    <AccountRedirect />
                  </Auth0Wrapper>
                }
              />
              <Route
                path="/account/client/*"
                element={<RedirectToClientApp />}
              />
              <Route
                path="/account/therapist/*"
                element={
                  <Auth0Wrapper>
                    <TherapistRouter />
                  </Auth0Wrapper>
                }
              />
            </Routes>
          </Router>
        </ApolloProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
