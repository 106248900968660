import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll/modules";
import AuthenticatedLayout from "../../layouts/AuthenticatedLayout/AuthenticatedLayout";
import NotFound from "../../pages/common/NotFound/NotFound";
import Connections from "../../pages/authenticated/therapist/Connections/Connections";
import { INavItem } from "../../components/Navigator/Navigator.types";
import EditProfile from "../../pages/authenticated/therapist/EditProfile/EditProfile";
import Logout from "../../pages/common/Logout/Logout";
import Resources from "../../pages/authenticated/therapist/Resources/Resources";

const menu: INavItem[] = [
  {
    label: "Edit Profile",
    action: "/account/therapist/edit-profile",
  },
  {
    label: "Referrals",
    action: "/account/therapist/referrals",
  },
  {
    label: "Resources",
    action: "/account/therapist/resources",
  },
];

const userMenu = [
  {
    label: "Logout",
    to: "/account/therapist/logout",
  },
];

function TherapistRouter() {
  const location = useLocation();

  useEffect(() => {
    scroll.scrollToTop({
      duration: 250,
    });
  }, [location]);

  return (
    <Routes>
      <Route element={<AuthenticatedLayout menu={menu} userMenu={userMenu} />}>
        <Route
          index
          element={<Navigate replace to="/account/therapist/referrals" />}
        />
        <Route path="referrals" element={<Connections />} />
        <Route path="edit-profile" element={<EditProfile />} />
        <Route path="resources" element={<Resources />} />
      </Route>
      <Route path="logout" element={<Logout to="https://alliconnect.com/therapist/"/>} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default TherapistRouter;
