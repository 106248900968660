import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const httpLink = (baseUri: string) =>
  new HttpLink({
    uri: `${baseUri}/app/graphql`,
    credentials: "include",
  });

const authLink = setContext((_, { headers }) => {
  let storage;
  const thisStorage = sessionStorage.getItem("user-storage");
  if (thisStorage) {
    const storageBuilder = JSON.parse(thisStorage)?.state?.user;
    if (storageBuilder) {
      storage = JSON.stringify(storageBuilder);
    }
  }
  if (storage) {
    const tokens = JSON.parse(storage);
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${tokens.accessToken}`,
      },
    };
  }
  return {
    headers: { ...headers },
  };
});

const client = (baseUri: string) =>
  new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink(baseUri)),
  });

export default client;
