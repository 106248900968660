import {useEffect} from "react";
import ReactGA from "react-ga4";

interface IUsePageViewProps {
    path: string
}

function usePageView({ path }: IUsePageViewProps) {
  return useEffect(() => {
    ReactGA.send({hitType: "pageview", page: path});
  }, []);
}

export default usePageView;