import React from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import FormSaveButtons from "../FormSaveButtons/FormSaveButtons";
import { IQuestionProps } from "../QuestionFactory/QuestionFactory.types";
import {
  GET_THERAPIST_PROFILE,
  THERAPIST_PROFILE_RESPONSE,
} from "../../../../../../api/graphql/gql_queries";
import {
  ProfileResponse,
  ProfileResponseVariables,
} from "../../../../../../api/graphql/gql_queries.types";

interface IMultiselectFormProps extends IQuestionProps {}

// TODO - update therapist data model to reflect therapy types
function MultiselectForm({
  question,
  responses,
  onCancel,
}: IMultiselectFormProps) {
  // removes nullable type issue
  const optionsMap = question.options!.map((o) => ({
    text: o!.text,
    code: o!.code,
    // eslint-disable-next-line no-underscore-dangle
    __typename: o!.__typename,
  }));

  const responsesMap = responses!.map((r) => r!);

  const { control, handleSubmit } = useForm<{
    options: string[];
  }>({
    defaultValues: {
      options: responsesMap,
    },
  });

  const [responseMutation, { loading, error }] = useMutation<
    ProfileResponse,
    ProfileResponseVariables
  >(THERAPIST_PROFILE_RESPONSE, {
    onCompleted: () => {
      onCancel("Successfully updated your responses");
    },
    refetchQueries: [{ query: GET_THERAPIST_PROFILE }, "GetProfile"],
  });

  const firstColumn = optionsMap.slice(0, Math.ceil(optionsMap.length / 2));
  const secondColumn = optionsMap.slice(Math.ceil(optionsMap.length / 2));

  const subtitle = (() => {
    if (question.maxResponsesAllowed === -1) {
      return "Select all that apply";
    }
    if (question.maxResponsesAllowed === 1) {
      return "Select a single option";
    }
    return `Select up to ${question.maxResponsesAllowed} options`;
  })();

  const isMaxSelected = (len: number) => {
    if (question.maxResponsesAllowed === -1) {
      return false;
    }
    if (len >= question.maxResponsesAllowed!) {
      return true;
    }

    return false;
  };

  return (
    <Box>
      <Typography variant="body2" color="grey.700">
        {subtitle}
      </Typography>
      <form
        onSubmit={handleSubmit(async (values) => {
          await responseMutation({
            variables: {
              questionCode: question.code,
              responses: values.options,
            },
          });
        })}
      >
        <FormGroup>
          <Controller
            control={control}
            render={({ field }) => (
              <Grid container spacing={3}>
                <Grid item>
                  <Stack>
                    {firstColumn.map((o) => (
                      <FormControlLabel
                        key={o.code}
                        control={
                          <Checkbox
                            {...field}
                            checked={
                              field.value.findIndex(
                                (f) => f.toLowerCase() === o.code!.toLowerCase()
                              ) > -1
                            }
                            onChange={() => {
                              if (
                                field.value.findIndex(
                                  (f) =>
                                    f.toLowerCase() === o.code!.toLowerCase()
                                ) > -1
                              ) {
                                // deselect
                                field.onChange(
                                  field.value.filter(
                                    (f) =>
                                      f.toLowerCase() !== o.code!.toLowerCase()
                                  )
                                );
                              } else {
                                if (isMaxSelected(field.value.length)) {
                                  return;
                                }
                                field.onChange([...field.value, o.code!]);
                              }
                            }}
                          />
                        }
                        label={o.text!}
                      />
                    ))}
                  </Stack>
                </Grid>
                <Grid item>
                  <Stack>
                    {secondColumn.map((o) => (
                      <FormControlLabel
                        key={o.code}
                        control={
                          <Checkbox
                            {...field}
                            checked={
                              field.value.findIndex(
                                (f) => f.toLowerCase() === o.code!.toLowerCase()
                              ) > -1
                            }
                            onChange={() => {
                              if (
                                field.value.findIndex(
                                  (f) =>
                                    f.toLowerCase() === o.code!.toLowerCase()
                                ) > -1
                              ) {
                                // deselect
                                field.onChange(
                                  field.value.filter(
                                    (f) =>
                                      f.toLowerCase() !== o.code!.toLowerCase()
                                  )
                                );
                              } else {
                                if (isMaxSelected(field.value.length)) {
                                  return;
                                }
                                field.onChange([...field.value, o.code!]);
                              }
                            }}
                          />
                        }
                        label={o.text!}
                      />
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            )}
            name="options"
          />
        </FormGroup>
        <FormSaveButtons
          onCancel={onCancel}
          loading={loading}
          error={Boolean(error)}
        />
      </form>
    </Box>
  );
}

export default MultiselectForm;
