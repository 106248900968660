import create from "zustand";
import {ClientConnection, IProfile, TherapistConnection, TherapistData} from "./queries.types";
import {ApiClient, AuthClient} from "./client";

const axios = require("axios").default;

export const getBootJson = async (): Promise<Record<string, any>> => {
  try {
    const resp = await axios.get(`/boot.json`);
    return resp.data as Record<string, any>;
  } catch (e) {
    throw new Error("Could not get boot.json");
  }
};

export const getClientConfig = async (): Promise<IClientConfig> => {
  try {
    const resp = await ApiClient.get(`/app/config`);
    return resp.data as IClientConfig;
  } catch (e) {
    throw new Error("Could not get client config");
  }
};

export const useBootStore = create<{
  boot: Record<string, string> | null;
  getBoot: () => Promise<void>;
}>((set) => ({
  boot: null,
  getBoot: async () => {
    set({
      boot: await getBootJson(),
    });
  },
}));

interface IUseClientConfig {
  config: IClientConfig | null;
  getConfig: () => Promise<void>;
}

export type IClientConfig = {
  name: string;
  value: string;
}[];

export const useClientConfig = create<IUseClientConfig>((set) => ({
  config: null,
  getConfig: async () => {
    set({
      config: await getClientConfig(),
    });
  },
}));

ApiClient.interceptors.request.use(
  (config: any) => {
    const bootStore = useBootStore.getState().boot;
    let storage;
    const thisStorage = sessionStorage.getItem("user-storage");
    if (thisStorage) {
      const storageBuilder = JSON.parse(thisStorage)?.state?.user;
      if (storageBuilder) {
        storage = JSON.stringify(storageBuilder);
      }
    }
    if (bootStore) {
      // eslint-disable-next-line no-param-reassign
      config.baseURL = bootStore.apiUrl;
    }
    if (storage) {
      const tokens = JSON.parse(storage);
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${tokens.accessToken}`;
    }
    return config;
  },
  (error: any) => Promise.reject(error)
);

AuthClient.interceptors.request.use(
  (config: any) => {
    let storage;
    const thisStorage = sessionStorage.getItem("user-storage");
    if (thisStorage) {
      const storageBuilder = JSON.parse(thisStorage)?.state?.user;
      if (storageBuilder) {
        storage = JSON.stringify(storageBuilder);
      }
    }
    if (storage) {
      const tokens = JSON.parse(storage);
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${tokens.accessToken}`;
    }
    return config;
  },
  (error: any) => Promise.reject(error)
);

export const getAllTherapists = async (): Promise<TherapistData[]> => {
  try {
    const resp = await ApiClient.get("/app/therapist-search/search");
    return resp.data;
  } catch (e) {
    throw new Error("Could not fetch therapists");
  }
};

export const getTherapist = async (id: string): Promise<TherapistData> => {
  try {
    const resp = await ApiClient.get(`/app/therapist-search/${id}`);
    return resp.data;
  } catch (e) {
    throw new Error(`Could not get therapist with id: ${id}`);
  }
};

export const contactTherapist = async (therapistId: string): Promise<void> => {
  try {
    const resp = await ApiClient.post(`/app/contact`, {
      contact: {
        therapistId,
      },
    });
    if (resp.status < 200 || resp.status >= 300) {
      throw new Error();
    }
    return Promise.resolve();
  } catch (e) {
    throw new Error(`Error with request`);
  }
};

export const logEvent = async (sub: string, event: string): Promise<void> => {
  try {
    const resp = await ApiClient.post(`/app/event`, {
      eventLog: {
        sub,
        event
      },
    });
    if (resp.status < 200 || resp.status >= 300) {
      throw new Error();
    }
    return Promise.resolve();
  } catch (e) {
    throw new Error(`Error with request`);
  }
};

export const uploadImage = async (image: Blob): Promise<{ path: string }> => {
  try {
    const fd = new FormData();
    fd.append("file", image);
    const resp = await ApiClient.post(`/app/therapists/me/images/profile`, fd, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    if (resp.status < 200 || resp.status >= 300) {
      throw new Error();
    }
    return Promise.resolve(resp.data);
  } catch (e) {
    throw new Error(`Error with request`);
  }
};

export const uploadFeatureImage = async (
  image: Blob
): Promise<{ path: string }> => {
  try {
    const fd = new FormData();
    fd.append("file", image);
    const resp = await ApiClient.post(
      `/app/therapists/me/images/features`,
      fd,
      {
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    );
    if (resp.status < 200 || resp.status >= 300) {
      throw new Error();
    }
    return Promise.resolve(resp.data);
  } catch (e) {
    throw new Error(`Error with request`);
  }
};

export const deleteProfileImage = async (): Promise<void> => {
  try {
    const resp = await ApiClient.delete(`/app/therapists/me/images/profile`);
    return resp.data;
  } catch (e) {
    throw new Error(`Could not get image`);
  }
};

export const deleteFeatureImage = async (id: string): Promise<void> => {
  try {
    await ApiClient.delete(`/app/therapists/me/images/features/${id}`);
    return;
  } catch (e) {
    throw new Error(`Could not delete image`);
  }
};

export const getTherapistPreview = async (): Promise<TherapistData> => {
  try {
    const resp = await ApiClient.get(`/app/therapist-search/preview`);
    return resp.data;
  } catch (e) {
    throw new Error(`Could not get therapist preview`);
  }
};

export const getTherapistConnections = async (): Promise<
  TherapistConnection[]
> => {
  try {
    const resp = await ApiClient.get(`/app/therapists/me/connections`);
    return resp.data;
  } catch (e) {
    throw new Error(`Could not get therapist connections`);
  }
};

export const getClientConnections = async (): Promise<
  ClientConnection[]
  > => {
  try {
    const resp = await ApiClient.get(`/app/clients/me/connections`);
    return resp.data;
  } catch (e) {
    throw new Error(`Could not get client connections`);
  }
};

export const acceptTherapistConnection = async (
  clientSub: string
): Promise<Boolean> => {
  try {
    const resp = await ApiClient.post(`/app/therapists/me/connections/accept`, {
      clientSub,
    });
    return resp.data;
  } catch (e) {
    throw new Error(`Could not accept connection`);
  }
};

export const declineTherapistConnection = async (
  clientSub: string
): Promise<Boolean> => {
  try {
    const resp = await ApiClient.post(
      `/app/therapists/me/connections/decline`,
      {
        clientSub,
      }
    );
    return resp.data;
  } catch (e) {
    throw new Error(`Could not accept connection`);
  }
};

export const clearSessionCookie = async () => {
  try {
    await ApiClient.post(`/app/clear-session`);
    return;
  } catch (e) {
    throw new Error(`Could not clear session`);
  }
};

export const getProfile = async (): Promise<IProfile> => {
  try {
    const resp = await ApiClient.get(`/app/profile`);
    return resp.data;
  } catch (e) {
    throw new Error(`Could not get profile`);
  }
};
