import { useMutation, useQueryClient } from "react-query";
import {logEvent} from "../../api/queries";
import {IUseEventLog} from "./useEventLog.types";

function useEventLog(): IUseEventLog {
  useQueryClient();
  const eventLogMutation = useMutation(
    ["event"],
    ({ sub, event }: { sub: string, event: string }) => logEvent(sub, event)
  );

  return {
    mutation: eventLogMutation,
  };
}

export default useEventLog;
