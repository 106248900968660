import React from "react";
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grow,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useQueryClient } from "react-query";
import RadiusButton from "../RadiusButton/RadiusButton";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "&.MuiDialog-paper": {
    overflowY: "visible !important",
  },
  "& .MuiPaper-root": {
    overflowY: "visible !important",
    paddingTop: `calc(50px + ${theme.spacing(2)})`,
    paddingBottom: theme.spacing(2),
    borderRadius: theme.spacing(4),
  },
}));

const AvatarContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "-50px",
  left: "calc(50% - 50px)",
  height: "100px",
  width: "100px",
  background: theme.palette.grey["400"],
  borderRadius: theme.spacing(5),
  border: `3px solid ${theme.palette.background.paper}`,
  "&::before": {
    position: "absolute",
    left: 0,
    top: "50%",
    marginTop: -7,
    marginLeft: -13,
    width: 10,
    height: 10,
    borderRight: `3px solid ${theme.palette.common.white}`,
    borderBottom: `3px solid ${theme.palette.common.white}`,
    borderBottomRightRadius: theme.spacing(5),
    content: '""',
  },
  "&::after": {
    position: "absolute",
    right: 0,
    top: "50%",
    marginTop: -7,
    marginRight: -13,
    width: 10,
    height: 10,
    borderLeft: `3px solid ${theme.palette.common.white}`,
    borderBottom: `3px solid ${theme.palette.common.white}`,
    borderBottomLeftRadius: theme.spacing(5),
    content: '""',
  },
}));

interface IReachOutModalProps {
  open: boolean;
  name: string;
  onClose: (e: any, reason: string) => void;
  onContact: () => void;
  loading: boolean;
  success: boolean;
}

function ReachOutModal({
  open,
  name,
  onClose,
  onContact,
  loading,
  success,
}: IReachOutModalProps) {
  const queryClient = useQueryClient();

  const handleClose = (e: any) => {
    onClose(e, "");
  };
  const onConfirm = async () => {
    await queryClient.refetchQueries("counselors-matched");
    onContact();
  };
  return (
    <StyledDialog open={open} onClose={onClose} fullWidth maxWidth="xs" data-test="reachOutDialog">
      <AvatarContainer>
        <Avatar
          sx={{ height: "100%", width: "100%", background: "transparent" }}
        />
      </AvatarContainer>
      <DialogContent sx={{ textAlign: "center" }}>
        <Stack spacing={2} sx={{ p: 4, pt: 0 }}>
          <Typography variant="h5" color="primary.light">
            {name}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {success
              ? `Thank you! ${name} will be reaching out soon.`
              : `Click Reach Out to request contact from ${name}.`}
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          sx={{ width: "100%" }}
        >
          <RadiusButton
            variant="contained"
            disableElevation
            sx={{
              pl: 5,
              pr: 5,
              pt: 2,
              pb: 2,
              color: "primary.main",
              bgcolor: "grey.200",

              ":hover": { bgcolor: "grey.300" },
            }}
            radius={3}
            onClick={handleClose}
          >
            {success ? "Close" : "Cancel"}
          </RadiusButton>
          <Grow in={!success && open} unmountOnExit>
            <div>
              <RadiusButton
                variant="contained"
                color="secondary"
                disableElevation
                sx={{
                  pl: 5,
                  pr: 5,
                  pt: 2,
                  pb: 2,
                }}
                radius={3}
                loading={loading}
                onClick={onConfirm}
              >
                Reach Out
              </RadiusButton>
            </div>
          </Grow>
        </Stack>
      </DialogActions>
    </StyledDialog>
  );
}

export default ReachOutModal;
