import React from "react";
import {Card, Modal, Stack, Typography} from "@mui/material";
import NameInputForm from "../NameInputForm/NameInputForm";

interface INameInputModalProps {
  open: boolean;
  onClose: () => void;
  showWelcomeMessage: boolean;
}

function NameInputModal({ open, onClose: onModalClose, showWelcomeMessage}: INameInputModalProps) {

  const handleClose = (event?: any, reason?: string) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    onModalClose();
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        sx={{
          p: 2,
          minWidth: "300px",
          maxWidth: "500px",
          maxHeight: "100vh",
          overflowY: "scroll",
        }}
      >
        <Stack
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          {showWelcomeMessage ?
            <Stack
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h3" color="grey.800" align="center">
                Welcome to Alli&nbsp;Connect!
              </Typography>
              <Typography variant="h5" color="grey.800" align="center">
                We are thrilled to have you join our community and be on your way to receiving specially matched referrals.
              </Typography>
              <Typography variant="h6" color="grey.800" align="center">
                Before we match you with clients, we need to know a little more about you!
              </Typography>
            </Stack>
            :
          <Typography variant="h6" color="grey.800">
            <b>
              Enter Your Name
            </b>
          </Typography>
          }
          <Stack spacing={2} alignItems="center">
            <NameInputForm onClose={handleClose} />
          </Stack>
        </Stack>
      </Card>
    </Modal>
  );
}

export default NameInputModal;