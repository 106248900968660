import React, { useState } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { PermMedia } from "@mui/icons-material";
import EditProfileCard from "../EditProfileCard/EditProfileCard";
import CounselorImageUpload from "../CounselorImageUpload/CounselorImageUpload";
import {
  deleteFeatureImage,
  uploadFeatureImage,
} from "../../../../../../api/queries";
import { IImage } from "../CounselorImageUpload/CounselorImageUpload.types";

interface IAdditionalPhotosCardProps {
  images: IImage[];
}

// TODO - query/cache stuff
function AdditionalPhotosCard({ images }: IAdditionalPhotosCardProps) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <EditProfileCard>
      <Stack spacing={1} alignItems="center">
        <div>
          <Typography variant="h6" textAlign="center">
            <b>Cover Photos</b>
          </Typography>
          <Typography variant="subtitle2" color="grey.600" textAlign="center">
            <i>Photos of you or your practice</i>
          </Typography>
        </div>
        <PermMedia sx={{ height: "80px", width: "80px", color: "grey.500" }} />
        <Button
          component="label"
          variant="outlined"
          color="primary"
          sx={{ width: "100%", maxWidth: "300px", p: 1 }}
          onClick={handleOpen}
          data-test="additionalPhotos"
        >
          Upload/Remove
        </Button>
      </Stack>
      <CounselorImageUpload
        open={open}
        onClose={handleOpen}
        onUpload={uploadFeatureImage}
        onRemove={deleteFeatureImage}
        multiple={images}
      />
    </EditProfileCard>
  );
}

export default AdditionalPhotosCard;
