import React from "react";
import { Box } from "@mui/material";
import LogoImage from "../../assets/logo.svg";

function Logo() {
  return (
    <Box sx={{ pt: 4 }}>
      <img src={LogoImage} height="64px" alt="Alli Connect" />
    </Box>
  );
}

export default Logo;
