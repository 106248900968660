import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function RedirectToClientApp() {
    const navigate = useNavigate();

    useEffect(() => {
        window.location.href = 'https://alliconnect.com/app';
    }, [navigate]);

    return null; // Render nothing or a loading spinner
}