import React from "react";
import { Grid } from "@mui/material";
import RadiusButton from "../../../../../../components/RadiusButton/RadiusButton";

interface IConnectionButtonsProps {
  onConfirm: () => void;
  onDecline: () => void;
  isDisabled?: boolean;
}

function ConnectionButtons({ onConfirm, onDecline, isDisabled }: IConnectionButtonsProps) {
  const sx = {
    height: { xs: "40px", sm: "50px" },
    minWidth: { xs: "110px", sm: "140px" },
  };
  return (
    <Grid container direction="row" spacing={2}>
      <Grid item>
        <RadiusButton
          disabled={isDisabled}
          radius={1}
          onClick={onDecline}
          variant="outlined"
          color="primary"
          sx={sx}
        >
          Decline
        </RadiusButton>
      </Grid>
      <Grid item>
        <RadiusButton
          disabled={isDisabled}
          radius={1}
          onClick={onConfirm}
          variant="contained"
          color="primary"
          sx={sx}
        >
          Accept
        </RadiusButton>
      </Grid>
    </Grid>
  );
}

export default ConnectionButtons;
