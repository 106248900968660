import React from "react";
import {
  Box,
  Chip,
  Container,
  Slide,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import VerifiedBanner from "../../../../../../components/VerifiedBanner/VerifiedBanner";
import RadiusButton from "../../../../../../components/RadiusButton/RadiusButton";
import ReachOutAlert from "../../../../../../components/ReachOutAlert/ReachOutAlert";
import { TherapistData } from "../../../../../../api/queries.types";
import CounselorImage from "../../../../../../components/CounselorImage/CounselorImage";

const FixedBar = styled(Box)<{ desktop?: boolean }>(({ theme, desktop }) => ({
  position: "fixed",
  zIndex: 2,
  ...(desktop
    ? {
        top: theme.mixins.toolbar.minHeight,
        marginTop: "8px",
      }
    : {
        bottom: 0,
      }),
  left: 0,
  right: 0,
  minHeight: "100px",
  background: theme.palette.grey["100"],
  boxShadow: "0px 0px 11px -3px rgba(0,0,0,0.65)",
}));

interface ISlideBarProps {
  visible?: boolean;
  counselor: TherapistData;
  onClick: () => void;
  desktop: boolean;
}

// TODO we suspect this file isn't actually rendering anything
function SlideBar({ visible, counselor, onClick, desktop }: ISlideBarProps) {
  const { id, givenName, familyName } = counselor.therapist.therapist;
  const { licenses, profileImagePath } = counselor.therapist;
  const abbreviatedName = `${givenName} ${familyName.charAt(0)}`;
  const fullName = `${givenName} ${familyName}`;

  const actionItem = (() => {
    if (counselor.contactStatus === "ACCEPTED") return "ACCEPTED";
    if (counselor.contactStatus === "PENDING") return "PENDING";
    if (counselor.contactStatus === "DECLINED") return "DECLINED";
    return "NONE";
  })();

  if (!desktop)
    return (
      <Slide in={visible} direction="up">
        <FixedBar>
          <Container sx={{ height: "100%", pt: 1, pb: 1 }}>
            <Stack
              direction="column"
              spacing={1}
              sx={{ height: "100%", width: "100%" }}
            >
              <Stack direction="row" alignItems="flex-start" spacing={1}>
                <CounselorImage
                  src={profileImagePath ?? ""}
                  alt={givenName}
                />
                <Stack direction="column">
                  <Stack direction="row" alignItems="flex-start">
                    <Typography variant="h5" sx={{ mr: 1 }}>
                      <b>{counselor.contactStatus === "ACCEPTED" ? fullName : abbreviatedName}</b>
                    </Typography>
                    <VerifiedBanner sx={{ paddingTop: "2px" }} />
                  </Stack>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    {actionItem === "NONE" ? (
                      <RadiusButton
                        variant="contained"
                        onClick={onClick}
                        sx={{ p: 1, width: "100%", mt: 1 }}
                        radius={3}
                      >
                        Reach Out
                      </RadiusButton>
                    ) : (
                      <ReachOutAlert status={counselor.contactStatus} mobile />
                    )}
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Container>
        </FixedBar>
      </Slide>
    );
  return (
    <Slide in={visible}>
      <FixedBar desktop>
        <Container sx={{ height: "100%", pt: 1 }}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ height: "100%", width: "100%" }}
            alignItems="center"
            flexWrap="wrap"
          >
            <CounselorImage src={`${id}.jpeg`} alt={givenName} />
            <Stack direction="column">
              <Stack direction="row" spacing={1}>
                <Typography variant="h4" sx={{ mr: 1 }}>
                  <b>{counselor.contactStatus === "ACCEPTED" ? fullName : abbreviatedName}</b>
                </Typography>
                <VerifiedBanner />
              </Stack>
              <Stack direction="row">
                {licenses &&
                  licenses.map((t) => (
                    <Chip
                      label={`${t.licenseNumber} (${t.licenseState})`}
                      sx={{ mt: 1, mr: 1 }}
                      size="small"
                      key={t.licenseNumber}
                    />
                  ))}
              </Stack>
            </Stack>
            <Box sx={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
              {actionItem === "NONE" ? (
                <RadiusButton
                  variant="contained"
                  onClick={onClick}
                  sx={{ p: 2, pl: 4, pr: 4 }}
                  radius={3}
                >
                  Reach Out
                </RadiusButton>
              ) : (
                <ReachOutAlert status={counselor.contactStatus} />
              )}
            </Box>
          </Stack>
        </Container>
      </FixedBar>
    </Slide>
  );
}

export default SlideBar;
