import React from "react";
import {Auth0Provider, CacheLocation} from "@auth0/auth0-react";
import {IClientConfig} from "../../api/queries";

interface IAuthProviderProps {
  config: IClientConfig;
  children: any;
}

function AuthProvider({config, children}: IAuthProviderProps) {
  const storageType : CacheLocation = "localstorage";

  const auth0ProviderConfig = {
    domain: config.find((c) => c.name === "auth0.domain")!.value,
    clientId: config.find((c) => c.name === "auth0.clientId")!.value,
    redirectUri: `https://${window.location.host}/account`,
    useRefreshTokens: true,
    cacheLocation: storageType
  };
  return (
      <Auth0Provider {...auth0ProviderConfig}>
        {children}
      </Auth0Provider>
  );
}

export default AuthProvider;