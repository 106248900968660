import React from "react";
import { Box, Typography } from "@mui/material";

interface IPreferenceDisplayProps {
  title: string;
  value: string;
}

function PreferenceDisplay({ title, value }: IPreferenceDisplayProps) {
  return (
    <Box>
      <Typography variant="body1" component="h6" color="grey.700">
        <b>{title}:</b>
      </Typography>
      <Typography variant="subtitle2" color="grey.600" component="span">
        {value}
      </Typography>
    </Box>
  );
}

export default PreferenceDisplay;
