import React from "react";
import { LocationOn } from "@mui/icons-material";
import { Box, SxProps, Theme, Typography } from "@mui/material";

interface ILocationDisplayProps {
  location: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
}

function LocationDisplay({
  location,
  disabled,
  sx = [],
}: ILocationDisplayProps) {
  if (!location) return null;
  return (
    <Box sx={Array.isArray(sx) ? sx : [sx]} data-test="location">
      <div>
        <LocationOn
          sx={(theme) => ({
            fontSize: "0.8rem",
            color: "grey.700",
            mr: "4px",
            ...(disabled && theme.variables.blur),
          })}
        />
        <Typography
          variant="subtitle1"
          color="grey.700"
          sx={(theme) => ({
            display: "inline-block",
            ...(disabled && theme.variables.blur),
          })}
        >
          {location}
        </Typography>
      </div>
    </Box>
  );
}

export default LocationDisplay;
