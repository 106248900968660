import React from "react";
import { useQuery } from "react-query";
import Error from "../../../../../../components/Error/Error";
import CounselorProfile from "../../../../client/CounselorProfile/CounselorProfile";
import { TherapistData } from "../../../../../../api/queries.types";
import { getTherapistPreview } from "../../../../../../api/queries";

interface ICounselorPreviewDataProviderProps {
  onBack: any;
}

function CounselorPreviewDataProvider({
  onBack,
}: ICounselorPreviewDataProviderProps) {
  const { isLoading, isError, data } = useQuery(
    "profilePreview",
    getTherapistPreview
  );

  if (isError) {
    return <Error centered />;
  }

  if (isLoading) {
    return <div />;
  }

  return (
    <CounselorProfile
      counselor={data! as TherapistData}
      onBack={onBack}
      preview
    />
  );
}

export default CounselorPreviewDataProvider;
