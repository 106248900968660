import React from "react";
import { Alert } from "@mui/material";

export enum AlertStatus {
  NONE,
  CONTACTED,
  REPLIED,
}

interface IReachOutAlertProps {
  status: string;
  mobile?: boolean;
}

function ReachOutAlert({ status, mobile }: IReachOutAlertProps) {
  switch (status) {
    case "ACCEPTED":
      return (
        <Alert severity="success" data-test="statusAccepted">
          {mobile ? "Accepted!" : "Counselor has accepted your request!"}
        </Alert>
      );
    case "PENDING":
      return (
        <Alert severity="info" data-test="statusContacted">
          {mobile ? "Contacted" : "You have reached out."}
        </Alert>
      );
    case "DECLINED":
      return (
        <Alert severity="error" data-test="statusDeclined">
          {mobile ? "Declined" : "Counselor has declined your request."}
        </Alert>
      );
    default:
      return null;
  }
}

export default ReachOutAlert;
