import React from "react";
import { useApolloClient, useMutation } from "@apollo/client";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import {
  SetPracticeDetails,
  SetPracticeDetailsVariables,
  TherapistProfile,
} from "../../../../../../api/graphql/gql_queries.types";
import {
  GET_THERAPIST_PROFILE,
  THERAPIST_PROFILE_SET_PRACTICE_DETAILS,
} from "../../../../../../api/graphql/gql_queries";
import FormSaveButtons from "../FormSaveButtons/FormSaveButtons";
import { CancelHandler } from "../ProfileTabs/ProfileTabs.types";
import { usStates } from "../../../../../../util/util";
import { Address, IPracticeDetailsForm } from "./PracticeDetailsForm.types";
import AddressAutocomplete from "../../../../../../components/AddressAutocomplete/AddressAutocomplete";
import useAddressValidation from "../../../../../../hooks/useAddressValidation/useAddressValidation";

const schema = yup.object({
  address: yup.object({
    state: yup.string().max(2).required("State is required"),
    city: yup.string().required("City is required"),
    street: yup.string().required("Street is required"),
    lat: yup.number().required(),
    long: yup.number().required(),
  }),
  phoneNumber: yup
    .string()
    .notRequired()
    .min(10, "Phone number must be in a valid format")
    .max(11, "Phone number must be in a valid format")
    .nullable()
    .transform((v) => v || null),
  website: yup
    .string()
    .matches(
        /^..+\...+$/gm,
      "Please enter a valid website. https://www.example.com"
    )
    .required(),
  practiceName: yup.string().required(),
  practiceEmail: yup.string().email().required(),
  hideAddress: yup.boolean().required(),
});

interface IPracticeDetailsFormProps {
  onCancel?: CancelHandler;
}

// TODO - add validation to fields
function PracticeDetailsForm({ onCancel }: IPracticeDetailsFormProps) {
  const client = useApolloClient();
  const therapist = client.readQuery<TherapistProfile>({
    query: GET_THERAPIST_PROFILE,
  });

  const { addressQuery } = useAddressValidation();

  const [practiceDetailsMutation, { loading, error }] = useMutation<
    SetPracticeDetails,
    SetPracticeDetailsVariables
  >(THERAPIST_PROFILE_SET_PRACTICE_DETAILS, {
    onCompleted: () => {
      if (onCancel) {
        onCancel("Successfully updated your practice details");
      }
    },
    refetchQueries: [{ query: GET_THERAPIST_PROFILE }, "GetProfile"],
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IPracticeDetailsForm>({
    defaultValues: {
      address: {
        street: therapist?.therapistProfile!.state!.therapist!.address ?? "",
        city: therapist?.therapistProfile!.state!.therapist!.city ?? "",
        state: therapist?.therapistProfile!.state!.therapist!.state ?? "",
        lat:
          (therapist?.therapistProfile!.state!.therapist!.latitude as number) ??
          "",
        long:
          (therapist?.therapistProfile!.state!.therapist!
            .longitude as number) ?? "",
      },
      website: therapist?.therapistProfile!.state!.therapist!.website ?? "",
      practiceName:
        therapist?.therapistProfile!.state!.therapist!.practiceName ?? "",
      practiceEmail:
        therapist?.therapistProfile!.state!.therapist!.practiceEmail ?? "",
      hideAddress:
        therapist?.therapistProfile!.state!.therapist!.hideAddress ?? true,
      phoneNumber:
        therapist?.therapistProfile!.state!.therapist!.phoneNumber ?? "",
    },
    resolver: yupResolver(schema),
  });

  return (
    <Box>
      <form
        onSubmit={handleSubmit(async (values) => {
          let practiceWebsite = (values.website as string).trim();
          if (practiceWebsite.length && !practiceWebsite.toLowerCase().startsWith('http')) {
            practiceWebsite = `https://${practiceWebsite}`;
          }
          await practiceDetailsMutation({
            variables: {
              latitude: values.address.lat,
              longitude: values.address.long,
              address: values.address.street,
              city: values.address.city,
              state: values.address.state,
              website: practiceWebsite,
              practiceName: values.practiceName,
              practiceEmail: values.practiceEmail,
              hideAddress: values.hideAddress,
              phoneNumber: values.phoneNumber,
            },
          });
        })}
      >
        <Stack spacing={3}>
          <Stack spacing={2}>
            <Controller
              control={control}
              render={({ field: { value } }) => (
                <AddressAutocomplete
                  onChange={async (v: Address | null) => {
                    if (v) {
                      // TODO - error messaging refactor someone please
                      try {
                        const res = await addressQuery(
                          v.street,
                          v.city,
                          v.state
                        );
                        setValue("address.street", v.street);
                        setValue("address.city", v.city);
                        setValue("address.state", v.state);
                        setValue("address.lat", res.lat);
                        setValue("address.long", res.long);
                      } catch (e) {
                        setValue("address.street", "");
                        setValue("address.city", "");
                        setValue("address.state", "");
                        setValue("address.lat", 0);
                        setValue("address.long", 0);
                      }
                    } else {
                      setValue("address.street", "");
                      setValue("address.city", "");
                      setValue("address.state", "");
                      setValue("address.lat", 0);
                      setValue("address.long", 0);
                    }
                  }}
                  value={value}
                />
              )}
              name="address"
            />
            <Controller
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="filled"
                  label="City"
                  error={Boolean(errors.address?.city)}
                  helperText={errors.address?.city?.message}
                />
              )}
              name="address.city"
            />
            <Controller
              control={control}
              render={({ field }) => (
                <FormControl variant="filled">
                  <InputLabel id="state-field">State</InputLabel>
                  <Select
                    labelId="state-field"
                    value={field.value}
                    label="State"
                    sx={{ minWidth: "80px", maxWidth: "120px" }}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    error={Boolean(errors.address?.state)}
                  >
                    {usStates.map((s) => (
                      <MenuItem key={s.abbreviation} value={s.abbreviation}>
                        {s.abbreviation}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={Boolean(errors.address?.state)}>
                    {errors.address?.state?.message}
                  </FormHelperText>
                </FormControl>
              )}
              name="address.state"
            />
            <Controller
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={field.value ?? false}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                      }}
                    />
                  }
                  label="Hide Address From Clients"
                />
              )}
              name="hideAddress"
            />
          </Stack>
          <Stack spacing={2}>
            <Controller
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="filled"
                  label="Practice Name"
                  error={Boolean(errors.practiceName)}
                  helperText={errors.practiceName?.message}
                />
              )}
              name="practiceName"
            />
            <Controller
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="filled"
                  label="Practice Email"
                  error={Boolean(errors.practiceEmail)}
                  helperText={errors.practiceEmail?.message}
                />
              )}
              name="practiceEmail"
            />
            <Controller
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="filled"
                  label="Website URL"
                  error={Boolean(errors.website)}
                  helperText={errors.website?.message}
                />
              )}
              name="website"
            />
            <Controller
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="filled"
                  label="Phone Number"
                  error={Boolean(errors.phoneNumber)}
                  helperText={errors.phoneNumber?.message}
                />
              )}
              name="phoneNumber"
            />
          </Stack>
        </Stack>
        <FormSaveButtons
          onCancel={onCancel}
          loading={loading}
          error={Boolean(error)}
        />
      </form>
    </Box>
  );
}

export default PracticeDetailsForm;
