import React from "react";
import {
  Box,
  Button,
  Card,
  Collapse,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";

const StyledAccordion = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: `${theme.spacing(2)} !important `,
  background: theme.palette.grey[200],
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

interface IEditableAccordionProps {
  expanded: boolean;
  preview?: (string | boolean | null)[] | null;
  example: string;
  title?: string | null;
  required: boolean;
  additionalAction?: React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
  onExpand: () => void;
}

function EditableAccordion({
  expanded,
  title,
  required,
  preview,
  example,
  children,
  onExpand,
  additionalAction,
}: IEditableAccordionProps) {
  return (
    <StyledAccordion data-test="accordion">
      <Box>
        <Stack
          direction="row"
          spacing={6}
          alignItems="center"
          justifyContent="space-between"
        >
          <div>
            <Typography variant="h6" whiteSpace="nowrap">
              <b>{title}</b>
              {required ? (
                <span style={{ color: "red" }}>*</span>
              ) : (
                <Typography variant="body1" sx={{ ml: 2, color: "grey.500" }}>
                  ( Optional )
                </Typography>
              )}
            </Typography>
            {!expanded && (
              <Typography variant="subtitle2" color="grey.600" data-test="data">
                {(preview &&
                  (preview?.filter((p) => p && p !== "" && p !== true).length > 1
                    ? preview?.join(", ")
                    : preview[0])) || <i>{example}</i>}
              </Typography>
            )}
          </div>
          {additionalAction}
          {!expanded && (
            <Button
              onClick={onExpand}
              variant="contained"
              color="primary"
              endIcon={<Edit />}
              sx={{
                bgcolor: "grey.50",
                color: "primary.main",
                "&:hover": {
                  bgcolor: "grey.200",
                },
              }}
            >
              Edit
            </Button>
          )}
        </Stack>
        <Collapse in={expanded} unmountOnExit mountOnEnter>
          {children}
        </Collapse>
      </Box>
    </StyledAccordion>
  );
}

export default EditableAccordion;
