import React from "react";
import { Box, Typography } from "@mui/material";

interface ICounselorSectionProps {
  title: string;
  children: React.ReactNode;
}

function CounselorSection({ title, children }: ICounselorSectionProps) {
  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        <b>{title}</b>
      </Typography>
      {children}
    </Box>
  );
}

export default CounselorSection;
