import React from "react";
import {Grid, Stack, Typography} from "@mui/material";
import usePageView from "../../../../hooks/usePageView/usePageView";
import PageContainer from "../../../../components/PageContainer/PageContainer";
import ResourceCard from "./components/ResourceCard/ResourceCard";
import FolderConfetti from "../../../../assets/folder-confetti.png";
import Par from "../../../../assets/par.png";
import PartnerCard from "./components/PartnerCard/PartnerCard";

// @ts-ignore
import PrivatePracticePdf from "../../../../assets/therapist-resources/build-a-private-practice-checklist.pdf";
// @ts-ignore
// import PrivatePracticeDocx from "../../../../assets/therapist-resources/build-a-private-practice-checklist.docx";
// @ts-ignore
import FinancialAgreementPdf from "../../../../assets/therapist-resources/thrivelution_financial_agreement.pdf";
// @ts-ignore
import FinancialAgreementDocx from "../../../../assets/therapist-resources/thrivelution_financial_agreement.docx";
// @ts-ignore
import TelehealthConsentPdf from "../../../../assets/therapist-resources/thrivelution_telehealth_consent.pdf";
// @ts-ignore
import TelehealthConsentDocx from "../../../../assets/therapist-resources/thrivelution_telehealth_consent.docx";
// @ts-ignore
import ReleaseOfInfoPdf from "../../../../assets/therapist-resources/thrivelution_release_of_info.pdf";
// @ts-ignore
import ReleaseOfInfoDocx from "../../../../assets/therapist-resources/thrivelution_release_of_info.docx";
// @ts-ignore
import MinorConsentPdf from "../../../../assets/therapist-resources/thrivelution_service_consent_minors.pdf";
// @ts-ignore
import MinorConsentDocx from "../../../../assets/therapist-resources/thrivelution_service_consent_minors.docx";
// @ts-ignore
import PrivacyPoliciesPdf from "../../../../assets/therapist-resources/thrivelution_privacy_policies.pdf";
// @ts-ignore
import PrivacyPoliciesDocx from "../../../../assets/therapist-resources/thrivelution_privacy_policies.docx";
// @ts-ignore
import BusinessAssocPdf from "../../../../assets/therapist-resources/thrivelution_business_assoc_agreement.pdf";
// @ts-ignore
import BusinessAssocDocx from "../../../../assets/therapist-resources/thrivelution_business_assoc_agreement.docx";
// @ts-ignore
import RecordsRequestPdf from "../../../../assets/therapist-resources/thrivelution_records_request.pdf";
// @ts-ignore
import RecordsRequestDocx from "../../../../assets/therapist-resources/thrivelution_records_request.docx";
// @ts-ignore
import ReducedFeePdf from "../../../../assets/therapist-resources/thrivelution_reduced_fee_agreement.pdf";
// @ts-ignore
import ReducedFeeDocx from "../../../../assets/therapist-resources/thrivelution_reduced_fee_agreement.docx";
// @ts-ignore
import InformedConsentPdf from "../../../../assets/therapist-resources/thrivelution_informed_consent_treatment.pdf";
// @ts-ignore
import InformedConsentDocx from "../../../../assets/therapist-resources/thrivelution_informed_consent_treatment.docx";
// @ts-ignore
import CovidConsentPdf from "../../../../assets/therapist-resources/thrivelution_covid_consent_inperson.pdf";
// @ts-ignore
import CovidConsentDocx from "../../../../assets/therapist-resources/thrivelution_covid_consent_inperson.docx";

function Resources() {

  usePageView({path: "/app/therapist/resources"});

  return (
    <PageContainer>
      <Grid
        container
        spacing={4}
        sx={{position: "relative"}}
      >
        <Grid item xs={12} sm={9} md={9} lg={8}>
          <Typography variant="h4" component="h1">
            <b>Resource Box</b>
          </Typography>
          <br/>
          <Typography variant="body1" component="p">
            Tools, tips, and tricks, including mental health-specific specialty business templates that will help you
            market, build, and scale your practice.
          </Typography>
          <br/>
          <Typography variant="subtitle1" component="p">
            All forms have been curated and tested by our therapist founder, Colleen Hilton, former group practice owner
            of Acuity Counseling, Seattle, WA.
          </Typography>
        </Grid>
        <br/>
        <Grid item xs={12} sm={3} md={3} lg={4}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
          <img src={FolderConfetti} alt="folder-confetti"/>
        </Grid>
      </Grid>
      <br/>
      <Typography variant="h5" component="h2" sx={{color: "#F27269"}}>
        Partners and Promotions
      </Typography>
      <br />
      <PartnerCard
        image={Par}
        alt="Par"
        link="https://www.parinc.com/?utm_source=PAR_logo&utm_medium=Thrivelution_sponsorship&utm_campaign=PAR_2022"
        description1=""
        description2="Get a 10% discount plus free Pic account and 3 free Pic uses for mental health providers"
        description3="PAR is a leading publisher of psychological assessment tools in print and digital formats."
        description4="We offer almost 500 instruments that cover constructs such as personality/mood, neuropsychology,
        forensics, achievement/development, intelligence/cognitive, behavior/health, and vocational. Our online
        assessment platform, PARiConnect, is the most reliable platform in our industry. It offers nearly 80
        instruments that can be administered online and is constantly updated with new features to enhance your
        user experience."
        buttonDescription="LEARN MORE ABOUT PAR"
      />
      <br />
      <Typography variant="h5" component="h2" sx={{color: "#F27269"}}>
        Start-Up Documents & Forms
      </Typography>
      <br/>
      <Typography variant="subtitle1" component="p">
        All the basics to start your own Private Practice
      </Typography>
      <Stack spacing={2} sx={{mt: 2}}>
        <ResourceCard
          title="CHECKLIST: Start A Private Practice"
          description="A kickstart to building the practice you've dreamed of"
          pdf={PrivatePracticePdf}
          docx=""/>
        <ResourceCard
          title="FINANCIAL AGREEMENT"
          description="Your payment agreement form between your client and yourself"
          pdf={FinancialAgreementPdf}
          docx={FinancialAgreementDocx}/>
        <ResourceCard
          title="TELEHEALTH CONSENT"
          description="Consent to participate in telehealth using Zoom Health"
          pdf={TelehealthConsentPdf}
          docx={TelehealthConsentDocx}/>
        <ResourceCard
          title="RELEASE OF INFORMATION (ROI)"
          description="Authorization to release/exchange confidential information"
          pdf={ReleaseOfInfoPdf}
          docx={ReleaseOfInfoDocx}/>
        <ResourceCard
          title="MINOR CONSENT FORM"
          description="Agreement to boundaries about your child’s confidentiality"
          pdf={MinorConsentPdf}
          docx={MinorConsentDocx}/>
        <ResourceCard
          title="PRIVACY POLICY/DISCLOSURES"
          description="Privacy Policy and disclosures/uses of healthcare information"
          pdf={PrivacyPoliciesPdf}
          docx={PrivacyPoliciesDocx}/>
        <ResourceCard
          title="BUSINESS ASSOCIATE AGREEMENT"
          description="Comply with the federal Standards for Privacy of Individually Identifiable Health Information"
          pdf={BusinessAssocPdf}
          docx={BusinessAssocDocx}/>
        <ResourceCard
          title="RECORDS REQUEST FORM"
          description="When a client asks for a copy of their records for themselves or to mail"
          pdf={RecordsRequestPdf}
          docx={RecordsRequestDocx}/>
        <ResourceCard
          title="REDUCED FEE AGREEMENT"
          description="Reduced fees for clients that cannot utilize any health insurance for services"
          pdf={ReducedFeePdf}
          docx={ReducedFeeDocx}/>
        <ResourceCard
          title="INFORMED CONSENT FOR TREATMENT"
          description="Confidentiality, and impacts of having a mental health treatment"
          pdf={InformedConsentPdf}
          docx={InformedConsentDocx}/>
        <ResourceCard
          title="COVID CONSENT FORM"
          description="Consent form for in-person sessions around Covid-19"
          pdf={CovidConsentPdf}
          docx={CovidConsentDocx}/>
      </Stack>
    </PageContainer>
  );
}

export default Resources;