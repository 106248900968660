import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    background: string;
    blue: string;
    variables: {
      blur: {
        textShadow: string;
        color: string;
        userSelect: string;
      };
      drawerWidth: string;
    };
  }

  interface ThemeOptions {
    background?: string;
    blue?: string;
    variables?: {
      blur?: {
        textShadow?: string;
        color?: string;
        userSelect?: string;
      };
      drawerWidth?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#6BBCB9", // green
      contrastText: "#fff",
    },
    secondary: {
      main: "#F27269", // orange
      contrastText: "#fff",
    },
    text: {
      primary: "#4E4946", // dark
    },
  },
  background: "#F2F6F6",
  blue: "#40AFCA",
  variables: {
    blur: {
      textShadow: "0 0 14px rgb(0 0 0 / 40%) !important",
      color: "transparent",
      userSelect: "none",
    },
    drawerWidth: "280px",
  },
});

export default theme;
